import fontResizeEventListener from '../../components/helpers/fontResizeEventListener.js'

const useFontSizeResponsive = ({
  form,
  keditor,
  component,
  label,
  customFormRef,
  inputMaxLength,
  fullWidth,
  isFirst,
  customComponentName,
}) => {
  const formRef = customFormRef || `font-size-responsive`
  const maxLength = inputMaxLength || 72
  // const element = form && form.find(`#${formRef}`)
  const componentName = customComponentName || 'fontSizeResponsive'
  const lockMobileConfig = `${formRef}-mobile-locked`
  const mobileConfigLocked = `${formRef}-lock`
  const defaultFontSize = `${formRef}-default`
  const mobileFontSize = `${formRef}-mobile`

  const size = (device) => `data-font_size_${device}`
  const element = (config) => form.find(`#${config}`)

  const init = (elementRef, callback) => {
    function handleChange(event) {
      const target = $(this).data('device')
      const { value } = event.target
      elementRef().attr(size(target), value)
      const mobileLockedDiv = form.find(`.${mobileConfigLocked}`)

      if (target === 'default' && elementRef().attr('data-lock') && JSON.parse(elementRef().attr('data-lock'))) {
        mobileLockedDiv.html(value)
        elementRef().attr(size('mobile'), value)
        element(mobileFontSize).val(value)
      }

      if (callback)
        callback({
          target,
          value,
          isBlocked: elementRef().attr('data-lock') && JSON.parse(elementRef().attr('data-lock')),
        })

      const currentWidth = keditor.options.currentDeviceWidth
      fontResizeEventListener(currentWidth, keditor)
    }

    element(mobileFontSize).on('change', handleChange)
    element(defaultFontSize).on('change', handleChange)

    element(lockMobileConfig).on('click', function (e) {
      const icon = $(this).find('i')
      const mobileLockedDiv = form.find(`.${mobileConfigLocked}`)

      if (icon.hasClass('fa-lock')) {
        icon.removeClass('fa-lock')
        icon.addClass('fa-unlock')
        //==============================
        element(mobileFontSize).show()
        mobileLockedDiv.hide()
        elementRef().attr('data-lock', false)
        if (callback) callback({ isBlocked: false })
      } else {
        icon.removeClass('fa-unlock')
        icon.addClass('fa-lock')
        //==============================
        element(mobileFontSize).hide()
        mobileLockedDiv.show()

        elementRef().attr('data-lock', true)

        mobileLockedDiv.html(element(defaultFontSize).val())
        element(mobileFontSize).val(element(defaultFontSize).val())

        elementRef().attr(size('mobile'), element(defaultFontSize).val())

        if (callback) callback({ isBlocked: true, target: 'mobile', value: element(defaultFontSize).val() })

        const currentWidth = keditor.options.currentDeviceWidth
        fontResizeEventListener(currentWidth, keditor)
      }
    })
  }

  const show = (elementRef) => {
    if (!elementRef().length) return
    if (!elementRef().attr(size('mobile')) && !elementRef().attr(size('default'))) {
      const fontSize = Math.floor(elementRef().css('font-size').replace('px', ''))
      elementRef().attr({ [size('mobile')]: fontSize, [size('default')]: fontSize })
    }

    element(mobileFontSize).val(elementRef().attr(size('mobile')))
    element(defaultFontSize).val(elementRef().attr(size('default')))

    const icon = element(lockMobileConfig).find('i')
    const mobileLockedDiv = form.find(`.${mobileConfigLocked}`)
    const lockView = {
      unlocked: () => {
        icon.removeClass('fa-lock')
        icon.addClass('fa-unlock')

        element(mobileFontSize).show()
        mobileLockedDiv.hide()
      },
      locked: () => {
        icon.removeClass('fa-unlock')
        icon.addClass('fa-lock')

        mobileLockedDiv.html(element(defaultFontSize).val())
        element(mobileFontSize).hide()
        mobileLockedDiv.show()
        elementRef().attr(size('mobile'), element(defaultFontSize).val())

        if (!elementRef().attr('data-lock')) elementRef().attr('data-lock', true)
      },
    }

    if (!elementRef().attr('data-lock') || JSON.parse(elementRef().attr('data-lock'))) {
      lockView.locked()
    } else {
      lockView.unlocked()
    }
  }

  const renderFontSizeOptions = () => {
    let sizes = ''
    for (let index = 12; index <= maxLength; index++) {
      sizes += `<option value="${index}">${index}</option>`
    }
    return sizes
  }

  const templateSelect = /*html*/ `
  <div class="col-sm-${fullWidth ? 12 : 6} ${!isFirst && 'mt-3'}">
    <div class="d-flex">
      <span
        id="${lockMobileConfig}"
        class="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock-config-icon" 
      >
        <i class="fa fa-unlock" aria-hidden="true"></i>
      </span>
      <label for="${formRef}" class="d-flex">
        ${label} Celular
      </label>
    </div>
    <div class="d-flex align-items-center">
      <div class="form-control ${mobileConfigLocked}" style="display: none; background: #f0f0f0"></div>
      <select id="${mobileFontSize}" class="form-control" data-device="mobile">
        ${renderFontSizeOptions()}
      </select>
    </div>
  </div>   
  `

  const templateNumber = /*html*/ `
  <div class="col-sm-${fullWidth ? 12 : 6} ${!isFirst && 'mt-3'}">
    <label>${label}</label>
    <div class="row">
      <div class="col">
        <div class="d-inline">
          <div class="">
            <div class="input-group input-group-sm">
              <input id="${defaultFontSize}" type="number" class="form-control" min="12" max="${maxLength}" data-device="default" />
              <div class="input-group-append">
                <span class="input-group-text">px</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   
  `

  const template = /*html*/ `
  ${templateNumber}
  ${templateSelect}
  `

  return {
    componentName,
    init,
    show,
    template,
    templateNumber,
  }
}

export default useFontSizeResponsive
