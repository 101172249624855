import { DEFAULT_ACTION_SELECT } from '../../constants'

const useDropdown = ({
  form,
  keditor,
  component,
  label,
  selectDefaultLabel,
  customFormRef,
  elementRef,
  customDropdownRef,
  customOptions,
}) => {
  const formRef = customFormRef || 'set-functionality'
  const element = form && form.find(`#${formRef}`)
  const dropdownButton = customDropdownRef || 'dropdown-set-functionality'
  let optionsStr = ''

  const options = customOptions || [
    {
      value: 'default-config',
      text: 'Ir para URL',
      icon: 'fa fa-globe',
    },
    {
      value: 'submit-form',
      text: 'Submeter Formulário',
      icon: 'fa fa-envelope',
    },
    {
      value: 'roll-to-element',
      text: 'Rolar para um elemento da página',
      icon: 'fa fa-arrows-v',
    },
    {
      value: 'open-popup',
      text: 'Abrir Popup da página',
      icon: 'fa fa-exclamation',
    },
  ]

  // Renderiza as opções
  options.forEach((option) => {
    const { value, text, icon } = option
    optionsStr += /*html*/ `
      <a class="dropdown-item d-flex" value="${value}" href="#">
          <div 
              class='col-sm-1 d-flex align-items-center justify-content-center dropdown-icon' 
              value="${value}"
          >
              <i class="${icon}" value="${value}" aria-hidden="true"></i>
          </div>
          <div class='text-value col-sm-11 align-self-center' value="${value}" >${text}</div>
      </a>
      `
  })

  const init = (callback) => {}

  const show = (callback) => {}

  const template = /*html*/ `
    <div>
        <label style="${!label ? 'display: none' : ''}">${label}</label>
        <div class="dropdown">
            <button 
                class="form-control dropdown-toggle" 
                type="button" 
                id="${dropdownButton}"
                data-toggle="dropdown" 
                aria-haspopup="true" 
                aria-expanded="false"
                style="height: 50px; font-size: 16px; display:flex; align-items: center; justify-content: space-between;"
            >
                ${!selectDefaultLabel ? DEFAULT_ACTION_SELECT : selectDefaultLabel}
            </button>
            <div class="dropdown-menu w-100 p-0" id="${formRef}" aria-labelledby="${dropdownButton}">
            ${optionsStr}
            </div>
        </div>
    </div>
  `

  return {
    componentName: 'dropdown',
    options,
    init,
    show,
    template,
  }
}

export default useDropdown
