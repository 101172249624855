/**
 * Microcomponent to handle multiple options of background. Default: Color, Image and Gradient
 * Works like a template, the logic is inside each backgroundType
 */
import CSS_CLASS from "../../keditor/constants/cssClass"

const useBackground = ({customTabs}) => {
    //const formRef = customFormRef || `background`
    const tabs = customTabs || [
        {
          name: 'Sólido',
          content: '',
          showClearOption: true,
          handle: () => {},
          initCallback: () => {}
        },
        {
          name: 'Degradê',
          content: '',
          showClearOption: true,
          handle: () => {}
        },
        {
          name: 'Imagem',
          content: '',
          showClearOption: true,
          handle: () => {}
        }
    ]
  
    const init = () => {
        tabs.forEach((tab) => {
            if (typeof tab.handle.init === "function") typeof tab.initCallback  === "function" ? tab.handle.init(tab.initCallback) : tab.handle.init()
        })
    }
    
    const show = () => {
        tabs.forEach((tab) => {
            if (typeof tab.handle.show === "function") tab.handle.show()
        })
    }

    const renderTabs = () => {
        let res = ''
        let classNavItemSize = tabs.length == 2 ? CSS_CLASS.BOOTSTRAP_NAV_ITEM_50 : CSS_CLASS.BOOTSTRAP_NAV_ITEM_33
        tabs.forEach((tab, index) => {
            res += /*html*/ `
                <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${classNavItemSize}">
                    <a class="${CSS_CLASS.BOOTSTRAP_NAV_LINK} ${index == 0 ? 'active' : ''}" id="form-bgtype-${index}" data-toggle="tab" href="#bgtype-${index}" role="tab" aria-controls="form" aria-selected="true">
                        ${tab.name}
                    </a>
                </li>
            `
        })
        return res
    }

    const renderClearConfig = (index) => {
        return /*html*/ `
            <div class="col-sm-12">
            <a href="#" style="font-size: 14px" id="clear-bg-${index}">Limpar configuração</a>
            </div>
        `
    }

    const renderTabsContents = () => {
        let res = ''
        tabs.forEach((tab, index) => {
            res += /*html*/ `
                <div class="tab-pane fade show ${index == 0 ? 'active' : ''}" id="bgtype-${index}" role="tab" aria-labelledby="form-bgtype-${index}"
                >
                    <div class="row">
                        ${tab.showClearOption ? renderClearConfig(index) : ''}
                        ${typeof tab.handle === "object" ? tab.handle.template : tab.content}
                    </div>
                </div>
            `
        })
        return res
    }
  
    const template = /*html*/ `
        <ul class="${CSS_CLASS.BOOTSTRAP_NAV} ${CSS_CLASS.BOOTSTRAP_NAV_TABS}" role="tablist">
            ${renderTabs()}
        </ul>
        <div class="tab-content mt-3 mx-2 bg-config" id="pills-tabContent">
            ${renderTabsContents()}
        </div> 
    `
  
    return {
      componentName: 'background',
      init,
      show,
      template,
    }

}
  
export default useBackground