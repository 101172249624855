const useRating = ({ form, keditor, component, elementRef, customOptions, customFormRef, isFirst = false }) => {
  const formRef = customFormRef || {
    type: 'rating-type',
    rating: 'rating-value',
    show: 'show-rating',
  }
  const element = (target) => form && form.find(`#${target}`)

  const renderStars = (rating) => {
    let stars = ''
    const fullStars = Math.floor(rating)
    const hasHalfStar = fullStars < rating
    for (let i = 1; i <= rating; i++) {
      stars += '<i class="fa fa-star mx-1"></i>'
    }

    if (hasHalfStar) stars += '<i class="fa fa-star-half-o mx-1"></i>'
    for (let i = rating; i <= 4; i++) {
      stars += '<i class="fa fa-star-o mx-1"></i>'
    }

    return stars
  }

  const init = (elementTarget, position) => {
    /**
     * configuração que define o rating
     */
    element(formRef.rating).on('change', function (e) {
      if (this.value < 0 || this.value > 5) {
        this.value = this.value < 0 ? 0 : 5
        return
      }
      elementTarget().attr('data-rating', this.value)
      const isStars = elementTarget().attr('data-rating-type') === 'stars'

      let newValue = `${this.value}/5`
      if (isStars) {
        newValue = renderStars(this.value)
      }

      elementTarget().html(newValue)
    })
  }

  const show = (elementTarget, position) => {
    element(formRef.rating).val(elementTarget().attr('data-rating'))
  }

  const template = /*html*/ `
    <div class="tab-content col-sm-6 ${!isFirst ? 'mt-3' : ''}">
      <label for="${formRef.rating}">Nota</label>
      <input 
        type="number"
        id="${formRef.rating}"
        class="form-control" 
        value=""
        min="0"
        max="5"
        step="0.5"
        placeholder="0"
      />
    </div>
    `

  return {
    componentName: 'rating',
    init,
    show,
    template,
  }
}

export default useRating
