const useText = ({ form, keditor, component, label, customFormRef, elementRef, placeHolder }) => {
  const formRef = customFormRef || `text-config`

  const element = form && form.find(`#${formRef}`)

  const init = () => {
    element.on('keyup', (event) => {
      elementRef().html(event.target.value)
    })
  }

  const show = () => {
    element.val(elementRef().text().trim())
  }

  const template = /*html*/ `
      <div class="tab-content col-sm-12">
        <label for=${formRef}>${label}</label>
        <input type="text" class="form-control" id=${formRef} placeholder="${placeHolder ? placeHolder : ''}" />
      </div>
    `

  return {
    componentName: 'text',
    init,
    show,
    template,
  }
}

export default useText
