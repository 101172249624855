const useWidth = ({
  form,
  keditor,
  component,
  elementRef,
  label,
  customOptions,
  customFormRef,
  fullWidth,
  isFirst,
  isMaxWidth = true,
  customClass = '',
}) => {
  const formRef = customFormRef || 'content-width'
  const element = form && form.find(`#${formRef}`)
  const positionList = customOptions || [
    { value: '350px', label: 'Pequeno' },
    { value: '550px', label: 'Médio' },
    { value: '750px', label: 'Grande' },
    { value: 'none', label: 'Auto' },
  ]

  const init = () => {
    element.on('change', ({ target: { value } } = e) => {
      elementRef().css({ [isMaxWidth ? 'maxWidth' : 'width']: value })
    })
  }

  const show = () => {
    element.val(
      elementRef()
        .css(isMaxWidth ? 'maxWidth' : 'width')
        .replace(isMaxWidth ? '' : 'px', '')
    )
  }

  const renderFontOptions = () => {
    let fonts = ''
    positionList.forEach((font) => {
      fonts += `<option value="${font.value}">${font.label}</option>`
    })
    return fonts
  }

  const maxWidthTemplate = /*html*/ `
    <div class="tab-content col-sm-${fullWidth ? 12 : 6} ${isFirst ? '' : 'mt-3'} ${customClass}">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderFontOptions()}
      </select>
    </div>
    `

  const numberTemplate = /*html*/ `
    <div class="col-sm-${fullWidth ? 12 : 6} ${isFirst ? '' : 'mt-3'} ${customClass}">
      <label>${label}</label>
      <div class="row">
        <div class="col">
          <div class="padding-box-inner d-inline">
            <div class="padding-box-inner-item">
              <div class="input-group input-group-sm">
                <input id="${formRef}" type="number" class="form-control" min="0" />
                <div class="input-group-append">
                  <span class="input-group-text">px</span>
                </div>
              </div>
            </div>
            <div class="padding-box-inner-item"></div>
          </div>
        </div>
      </div>
    </div>   
    `

  return {
    componentName: 'widthSelector',
    init,
    show,
    maxWidthTemplate,
    numberTemplate,
  }
}

export default useWidth
