const useBorderInputOptions = ({ form, keditor, component, label, componentName, elementRef }) => {
  const formRef = `${componentName}-border-input-options`;
  const element = form && form.find(`#${formRef}`);
  const options = [
    {
      id: 0,
      name: 'Padrão',
      value: {
        borderRadius: '3.76px',
        borderStyle: 'solid',
      },
    },
    {
      id: 1,
      name: 'Quadrado',
      value: {
        borderRadius: '0px',
        borderStyle: 'solid',
      },
    },
    {
      id: 2,
      name: 'Arredondado',
      value: {
        borderRadius: '10px',
        borderStyle: 'solid',
      },
    },
    {
      id: 3,
      name: 'Linha',
      value: {
        borderRadius: '3.76px',
        borderStyle: 'none',
        borderRadius: '0px',
      },
    },
  ];

  const init = () => {
    element.on('change', (event) => {
      const option = options.filter((el) => el.id === parseInt(event.target.value))[0];
      elementRef().attr('input-style', option.id);
      elementRef().css('border-radius', option.value.borderRadius);
      elementRef().css('border-right-style', option.value.borderStyle);
      elementRef().css('border-left-style', option.value.borderStyle);
      elementRef().css('border-top-style', option.value.borderStyle);
    });
  };

  const show = () => {
    const inputStyle = elementRef().attr('input-style') || 0;
    const option = options.filter((el) => el.id === parseInt(inputStyle))[0];
    element.val(option.id);
  };

  const renderOptions = () => {
    let acc = '';

    options.forEach((element) => {
      acc += `<option value="${element.id}">${element.name}</option>`;
    });

    return acc;
  };

  const template = /*html*/ `
    <div class="col-sm-6 mt-3">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderOptions()}
      </select>
    </div>   
  `;

  return {
    componentName: 'borderInputOptions',
    init,
    show,
    template,
  };
};

export default useBorderInputOptions;
