import pSBC from '../helpers/pSBC.js'

const useBoxShadow = ({ form, keditor, component, label, customFormRef, elementRef, customOptions }) => {
  const formRef = customFormRef || `box-shadow-editor`
  const element = form && form.find(`#${formRef}`)
  const options = customOptions || [
    {
      name: 'Sem sombra',
      value: 0,
      hex: 'none',
    },
    {
      name: 'Sombra leve',
      value: 1,
      hex: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
    {
      name: 'Sombra alta',
      value: 2,
      hex: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
    {
      name: 'Sombra Elevação',
      value: 3,
      hex: (light, color, dark) => `
      0 5px 0 0 ${color}, 
      0 0px 0px 0px ${dark}
      `,
      //   hex: () => `0 10px 10px -1px rgb(43 156 92 / 50%), 0 5px 0 0 #3cc979, 0 10px 15px -3px rgb(60 201 121 / 15%)`
    },
  ]

  const setBoxShadow = (event) => {
    const option = options.filter((option) => option.value === parseInt(event.target.value))
    let hex = option.length && option[0].hex
    if (parseInt(event.target.value) === 3) {
      const color = pSBC(-0.4, elementRef().css('background-color'))
      const colorDark = pSBC(0.5, color)
      const colorLight = pSBC(0.14, color)
      hex = hex(colorLight, color, colorDark)
    }

    elementRef().css('box-shadow', hex)
    elementRef().attr('data-box-shadow-type', event.target.value)
  }

  const init = () => {
    element.on('change', setBoxShadow)
  }

  const show = () => {
    element.val(elementRef().attr('data-box-shadow-type') || 0)
  }

  const renderOptions = () => {
    let acc = ''

    options.forEach((element) => {
      acc += `<option value="${element.value}">${element.name}</option>`
    })

    return acc
  }

  const template = /*html*/ `
    <div class="col-sm-6 mt-3">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderOptions()}
      </select>
    </div>   
  `

  return {
    componentName: 'boxShadow',
    init,
    show,
    setBoxShadow,
    template,
  }
}

export default useBoxShadow
