const inputSelection = (position) => /*html*/ `
<div class="border-block">
  <div class="input-group input-group-sm">
    <input
      id="${position}"
      type="number"
      class="form-control radius-position"
      placeholder="--"
      value
    />
    <div class="input-group-append">
      <span class="input-group-text">px</span>
    </div>
  </div>
</div>
`

export const FORM_BORDER = /*html*/ `
<div class="d-flex flex-column align-items-center">
  <div class="row col-sm-12 p-0 justify-content-between">
    ${inputSelection('top-left')}
    <div class="border-lt"></div>
    <div class="border-rt"></div>
    ${inputSelection('top-right')}
  </div>
  <div>
    <span
      class="d-flex rounded text-center lock-config justify-content-center align-items-center"
      id="lock-border-config" 
    >
      <i class="fa fa-unlock" aria-hidden="true"></i>
    </span>
  </div>
  <div class="row col-sm-12 p-0 justify-content-between">
    ${inputSelection('bottom-left')}
    <div class="border-lb"></div>
    <div class="border-rb"></div>
    ${inputSelection('bottom-right')}
  </div>
</div>
`
