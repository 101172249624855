
const useStickyToggleClose = ({form, keditor, elementRef, customFormRef, label}) => {

    const SICKYCLOSEATTR = `data-sticky-close`
    const formRef = customFormRef || `switch-sticky-close-editor`
  
    const init = () => {
      form.find(`#${formRef}`).on('click', (event) => {
        if ($(event.target).prop('checked'))
          elementRef().attr(SICKYCLOSEATTR, 'y')
        else
          elementRef().removeAttr(SICKYCLOSEATTR)
      })
    }
  
    const show = () => {
      form.find(`#${formRef}`).prop('checked', !!elementRef().attr(SICKYCLOSEATTR))
    }
  
    const template = /*html*/ `
    <div class="d-flex col-sm-12">
      <label class="col-sm-10 p-0 m-0">${label}</label>
      <div class="col-sm-2 p-0 m-0">
        <div class="button-switch col-sm-2">
          <input 
            type="checkbox" 
            id="${formRef}" 
            class="switch" 
          />
          <label for="${formRef}" class="lbl-off"></label>
          <label for="${formRef}" class="lbl-on"></label>
        </div>
      </div>
    </div>
  `
  
    return {
      componentName: 'stickyToggleClose',
      init,
      show,
      template,
    }
  }
  
  export default useStickyToggleClose