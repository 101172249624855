import ColorPicker from '../helpers/colorPicker'
const colorPicker = new ColorPicker()

const useColor = ({ form, keditor, component, label, elementRef, customFormRef, fullWidth, isFirst }) => {
  const formRef = customFormRef || `color-editor`

  const init = (callback) => {
    //Cor do texto
    colorPicker.init(
      form,
      (color) => {
        elementRef().css('color', `${color}`)
        if (callback) callback(color)
      },
      `#${formRef}`
    )
  }

  const show = (callback) => {
    colorPicker.show(form, !callback ? elementRef().css('color') : callback(), `#${formRef}`)
  }

  const template = /*html*/ `
  <div class="col-sm-${fullWidth ? 12 : 6} ${!isFirst && 'mt-3'}">
    <label for="${formRef}">${label}</label>
    <div class="input-group">
      <input data-id="#${formRef}" class="color-picker-component form-control" />
    </div>
  </div>   
  `

  return {
    componentName: 'color',
    init,
    show,
    template,
  }
}

export default useColor
