import { tabBackground, tabBackgroundGradient, tabBackgroundImage } from './templates/background'
import { FORM_BORDER } from './templates/borders'
import { navTab } from './templates/navBar'

const tabs = [
  {
    id: 'background-color',
    tabName: 'Sólido',
    tabContent: tabBackground,
    isActive: true,
  },
  {
    id: 'background-gradient',
    tabName: 'Degradê',
    tabContent: tabBackgroundGradient,
    isActive: false,
  },
  {
    id: 'background-image',
    tabName: 'Imagem',
    tabContent: tabBackgroundImage,
    isActive: false,
  },
]

export const FORM_LAYOUT = /*html*/ `
<div class="form-settings-head">
  Layout<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>

<div class="form-group" style="margin: 1rem 0rem;">

  <div class="tab-content col-lg-12 col-sm-12">
    <label for="container-size">Largura do container</label>
    <select id="container-size" class="form-control">
      <option value="normal">Padrão</option>
      <option value="cc-w-fluid">Tela Cheia</option>
      <option value="cc-w-small">Menor</option>
    </select>
  </div>
</div>

<div class="form-settings-head">
  Fundo<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>

<div class="form-custom" style="margin: 1rem 0rem;">
  ${navTab({ customTabs: tabs })}
</div>
<div class="form-settings-head">
  Bordas<span
    ><i class="fa fa-caret-down" aria-hidden="true"></i
  ></span>
</div>
<div class="form-group" style="margin: 1rem 0rem;">
  <div class="tab-content col-sm-12 ">
    <div class="raio-group">
      <label for="input-color">Estilo da borda</label>
      <select id="border-style" class="form-control">
        <option value="solid">Sólido</option>
        <option value="dashed">Tracejado</option>
        <option value="dotted">Pontilhado</option>
      </select>
    </div>
    
    <div class="raio-group">
      <label for="input-color" class="mt-3">Espessura da borda</label>
      <input type="number" class="form-control" id="bg-border" value="0" />
    </div>
    <div class="raio-group">
      <label for="input-color" class="mt-3">Cor da borda</label>      
      <input data-id="#bg-border-color" class="color-picker-component form-control" />
    </div>

    <label for="border-radius" class="mt-3">Curvatura da borda</label>
    ${FORM_BORDER}
  </div>
</div>
`
