import ColorPicker from '../helpers/colorPicker.js'
const colorPicker = new ColorPicker()

const useTextShadow = ({ form, keditor, component, customFormRef, customOptions, isFirst = false }) => {
  const formRef = customFormRef || `text-shadow`
  const element = form && form.find(`#${formRef}`)
  const defaultColor = '#222'

  const data = (type = 'type') => `data-text_shadow_${type}`

  const options = customOptions || [
    {
      name: 'Nenhum',
      value: 0,
      hex: 'none',
    },
    {
      name: 'Chapada',
      value: 1,
      hex: `2px 2px 0px`,
    },
    {
      name: 'Borrada',
      value: 2,
      hex: `2px 2px 5px`,
    },
    {
      name: 'Brilho',
      value: 3,
      hex: `2px 2px 30px`,
    },
  ]

  const init = (elementRef) => {
    element.on('change', function (e) {
      elementRef().attr(data(), e.target.value)
      const color = elementRef().attr(data('color')) || defaultColor
      const findHex = options.find((el) => el.value == e.target.value)
      if (findHex && findHex.hex != 'none') {
        return elementRef().css('textShadow', `${findHex.hex} ${color}`)
      }
      return elementRef().css('textShadow', `none`)
    })

    colorPicker.init(
      form,
      (color) => {
        elementRef().attr(data('color'), `${color}`)
        const findHex = options.find((el) => el.value == elementRef().attr(data()))
        if (findHex) {
          return elementRef().css('textShadow', `${findHex.hex} ${color}`)
        }
      },
      `#${formRef}-color`
    )
  }

  const show = (elementRef) => {
    element.val(elementRef().attr(data()) || 0)

    colorPicker.show(form, elementRef().attr(data('color')) || defaultColor, `#${formRef}-color`)
  }

  const renderOptions = () => {
    let acc = ''

    options.forEach((element) => {
      acc += /*html*/ `<option value="${element.value}">${element.name}</option>`
    })

    return acc
  }

  const template = /*html*/ `
    <div class="col-sm-6 ${!isFirst ? 'mt-3' : ''}">
      <label for="${formRef}">Estilo</label>
      <select id="${formRef}" class="form-control">
        ${renderOptions()}
      </select>
    </div>   
    <div class="col-sm-6 ${!isFirst ? 'mt-3' : ''}">
      <label for="${formRef}-color">Cor</label>
      <div class="input-group">
        <input data-id="#${formRef}-color" class="color-picker-component form-control" />
      </div>
    </div>   
  `

  return {
    componentName: 'textShadow',
    init,
    show,
    template,
  }
}

export default useTextShadow
