const WRAPPER = `wrapper_${new Date().getTime()}`
const LINK = 'url'
const LINK_TARGET_TYPE = 'link-target-type'

const useWrapperWithLink = ({ form, keditor, customFormRef, elementRef, componentTarget, isFirst }) => {
  const element = (target) => form && form.find(`#${target}`)
  const create = (component) => {
    component.find('.anchor').on('click', function (e) {
      e.preventDefault()
    })
  }

  const init = () => {
    function renderAnchor(hrefVal, targetVal) {
      return /*html*/ `<a 
      class='anchor' 
      href='${hrefVal}' 
      rel="noopener noreferrer" 
      target='${targetVal}'
      style="text-decoration: none; color:inherit;"
    ></a>
  `
    }

    element(LINK).on('keyup', function (e) {
      const target = elementRef().find(componentTarget)
      if (elementRef().find('.anchor').length) {
        if (!this.value.length) return target.unwrap()
        elementRef().find('.anchor').attr('href', this.value)
      } else {
        target.wrap(renderAnchor(this.value, element(LINK_TARGET_TYPE).val()))
      }

      target.on('click', function (e) {
        e.preventDefault()
      })
    })

    element(LINK_TARGET_TYPE).on('change', function (e) {
      const target = elementRef().find(componentTarget)

      if (elementRef().find('.anchor').length) elementRef().find('.anchor').attr('target', this.value)
      else target.wrap(renderAnchor('#', this.value))

      target.on('click', function (e) {
        e.preventDefault()
      })
    })
  }

  const show = () => {
    form.find(`#${LINK}`).val(elementRef().find('.anchor').attr('href'))
    form.find(`#${LINK_TARGET_TYPE}`).val(elementRef().find('.anchor').attr('target'))
  }

  const template = /*html*/ `
    <div class="col-sm-12" id="${WRAPPER}">
      <label for="${LINK}" class="${!isFirst && 'mt-3'}">Link externo</label>
      <div>
        <input 
          type="text"
          id="${LINK}"
          class="form-control" 
          value=""
          placeholder="Link URL" 
          aria-label="Link externo"
        />
      </div>
      <label for="${LINK_TARGET_TYPE}" class="mt-3">O link abrirá:</label>
      <div>
        <select id="${LINK_TARGET_TYPE}" class="form-control">
          <option value="">Na mesma página</option>
          <option value="__blank">Em outra aba</option>
        </select>
      </div>
    </div>
    `

  return {
    componentName: 'wrapperLink',
    init,
    show,
    create,
    template,
  }
}

export default useWrapperWithLink
