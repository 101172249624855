import CSS_CLASS from '../../keditor/constants/cssClass.js'
import { TIMEDELAY_FORM_LAYOUT } from './timeDelay/templateForm.js'
import { ANIMATIONS_FORM_LAYOUT } from './animations/templateForm.js'
import useSpacing from '../microComponents/Spacing.js'
const Spacing = useSpacing({ isFirst: true })
/**
 * Retorna o template que será usada na config do elemento
 * @param {String} children template definido por cada elemento
 * @param {String} advancedTemplate template de configuração avançada
 * @param {Object} styleConfig template para a aba de estilos
 */
export const templateForm = ({ children, advancedTemplate = '', styleConfig = '', newTabs, isContainer = false }) => {
  const random = Math.floor(Math.random() * 99999)
  const classNavItem =
    (styleConfig && styleConfig.length) || (newTabs && newTabs.length)
      ? CSS_CLASS.BOOTSTRAP_NAV_ITEM_33
      : CSS_CLASS.BOOTSTRAP_NAV_ITEM_50
  return /*html*/ `
    <ul class="${CSS_CLASS.BOOTSTRAP_NAV} ${CSS_CLASS.BOOTSTRAP_NAV_TABS}" role="tablist">
      <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${classNavItem}">
        <a class="${
          CSS_CLASS.BOOTSTRAP_NAV_LINK
        } active" id="form-tab" data-toggle="tab" href="#config-content_${random}"
          role="tab" aria-controls="form" aria-selected="true">
          Configuração
        </a>
      </li>
      ${renderNewTabs(newTabs)}
      <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${classNavItem}">
        <a class="${CSS_CLASS.BOOTSTRAP_NAV_LINK}" id="button-tab" data-toggle="tab"
          href="#advanced-config-content_${random}" role="tab" aria-controls="advanced" aria-selected="true">
          Avançado
        </a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="config-content_${random}" role="tab" aria-labelledby="form-tab">
        <div class="form-horizontal">
          ${children}
          ${spacingSession(isContainer)}
        </div>
      </div>
      <div class="tab-pane fade" id="advanced-config-content_${random}" role="tab" aria-labelledby="button-tab">
        ${advancedTemplate}
        ${advancedSession}
      </div>
      ${renderNewContentTabs(newTabs)}
    </div>
  `
}

const renderNewTabs = (tabs) => {
  if (!tabs) return ''
  let aggregator = ''
  tabs.map((tab) => {
    aggregator += /*html*/ `
    <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_33}">
      <a
        class="${CSS_CLASS.BOOTSTRAP_NAV_LINK}"
        id="${tab.id}"
        data-toggle="tab" href="#${tab.href}"
        role="tab"
        aria-controls="form"
        aria-selected="true"
      >
        ${tab.label}
      </a>
    </li>
    `
  })
  return aggregator
}

const renderNewContentTabs = (tabs) => {
  if (!tabs) return ''
  let aggregator = ''
  tabs.map((tab) => {
    aggregator += /*html*/ `
    <div
        class="tab-pane fade"
        id="${tab.href}"
        role="tab"
        aria-labelledby="${tab.id}"
    >
      <div class="form-horizontal" >
        ${tab.content}
      </div>
    </div>
    `
  })
  return aggregator
}

export function hideMobileDesktop(form, component, keditor, target, type) {
  function switchCheckbox(btn, target, hiddentTarget) {
    if ($(btn).prop('checked')) {
      target.addClass(`hidden-${hiddentTarget}`)
    } else {
      target.removeClass(`hidden-${hiddentTarget}`)
    }
  }

  switch (type) {
    case 'edit':
      form.find('.hide-mobile').on('click', function () {
        const componentTarget = keditor.getSettingComponent().find(target)
        switchCheckbox(this, componentTarget, 'mobile')
      })
      form.find('.hide-desktop').on('click', function () {
        const componentTarget = keditor.getSettingComponent().find(target)
        switchCheckbox(this, componentTarget, 'desktop')
      })
      break
    case 'edit-text':
      form.find('.hide-mobile').on('click', function () {
        const componentTarget = keditor.getSettingComponent().find(target).children().children()

        let comp = componentTarget.hasClass('custom-container') ? componentTarget : componentTarget.parent()

        switchCheckbox(this, comp, 'mobile')
      })
      form.find('.hide-desktop').on('click', function () {
        const componentTarget = keditor.getSettingComponent().find(target).children().children()

        let comp = componentTarget.hasClass('custom-container') ? componentTarget : componentTarget.parent()

        switchCheckbox(this, comp, 'desktop')
      })
      break
    case 'edit-container':
      form.find('.hide-mobile').on('click', function () {
        const section = keditor.getSettingContainer().find(target).first()
        const sectionInner = section.children()
        switchCheckbox(this, sectionInner, 'mobile')
      })
      form.find('.hide-desktop').on('click', function () {
        const section = keditor.getSettingContainer().find(target).first()
        const componentTarget = section.children()

        switchCheckbox(this, componentTarget, 'desktop')
      })
      break
    default:
      form.find('.hide-mobile').prop('checked', component.hasClass('hidden-mobile'))
      form.find('.hide-desktop').prop('checked', component.hasClass('hidden-desktop'))

      break
  }
}

export function fontSizeOptions(limit) {
  let option = ''
  for (let i = 12; i <= 42; i++) {
    option += `<option value="font-${i}">mobile-font-size ${i}px</option>`
  }

  return option
}

export const collapseConfig = () => {
  $('.form-settings-head').on('click', function () {
    var curelement = $(this)
    var nextGroup = curelement.next()
    nextGroup.slideToggle('fast', function () {
      nextGroup.css('display') == 'none'
        ? curelement.find('i').removeClass('fa-caret-down').addClass('fa-caret-right')
        : curelement.find('i').removeClass('fa-caret-right').addClass('fa-caret-down')
    })
  })
  $('#widthSwitch').on('change', function () {
    $('#cs-maxwidth')[$(this)[0].checked ? 'show' : 'hide']()
  })
}

const spacingSession = (isContainer) => /*html*/ `
<div class="form-settings-head">
  Espaçamentos<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group row" style="margin: 1rem 0rem">
  <div class="mt-3">
    <h6 class="col-sm-12 my-3"
      >Externo Superior
      <i class="fa fa-info-circle" aria-hidden="true" style="font-size: 14px"></i
    ></h6>
    ${Spacing.template}
  </div>
  ${isContainer ? padding : ''}
</div>`

const padding = /*html*/ `
<div class="col-sm-12">
<h6 class="my-3">
  Interno
  <i class="fa fa-info-circle" aria-hidden="true" style="font-size: 14px"></i
></h6>
<div class="padding-box">
  <div class="padding-box-inner">
    <div class="padding-box-inner-item config-corner-lt"></div>
    <div class="padding-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="top"
          type="number"
          min="0"
          class="form-control p-element"
          placeholder="--"
          value
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="padding-box-inner-item config-corner-rt"></div>
  </div>
  <div class="padding-box-inner">
    <div class="padding-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="left"
          type="number"
          min="0"
          class="form-control p-element"
          placeholder="--"
          value
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="padding-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="all"
          type="number"
          min="0"
          class="form-control p-element"
          placeholder="--"
          value
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="padding-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="right"
          type="number"
          min="0"
          class="form-control p-element"
          placeholder="--"
          value
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
  </div>
  <div class="padding-box-inner">
    <div class="padding-box-inner-item config-corner-lb"></div>
    <div class="padding-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="bottom"
          type="number"
          min="0"
          class="form-control p-element"
          placeholder="--"
          value
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="padding-box-inner-item config-corner-rb"></div>
  </div>
</div>
</div>`

const oldSpacing = /*html*/ `<div class="col-sm-12 mt-3">
<label class="mt-3"
  >Espaçamento Externo
  <i class="fa fa-info-circle" aria-hidden="true" style="font-size: 14px"></i
></label>
<div class="margin-box">
  <div class="margin-box-inner">
    <div class="margin-box-inner-item"></div>
    <div class="margin-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="top"
          type="number"
          class="form-control m-element"
          placeholder="--"
          value
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="margin-box-inner-item"></div>
  </div>
  <div class="margin-box-inner">
    <div class="margin-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="left"
          type="number"
          class="form-control m-element"
          placeholder="--"
          value
          disabled
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="margin-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="all"
          type="number"
          class="form-control m-element"
          placeholder="--"
          value
          disabled
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="margin-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="right"
          type="number"
          class="form-control m-element"
          placeholder="--"
          value
          disabled
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
  </div>
  <div class="margin-box-inner">
    <div class="margin-box-inner-item"></div>
    <div class="margin-box-inner-item">
      <div class="input-group input-group-sm">
        <input
          id="bottom"
          type="number"
          class="form-control m-element"
          placeholder="--"
          value
        />
        <div class="input-group-append">
          <span class="input-group-text">px</span>
        </div>
      </div>
    </div>
    <div class="margin-box-inner-item"></div>
  </div>
</div>
</div>`

const advancedSession = /*html*/ `
<div class="form-settings-head">
  Classes e ID<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group row" style="margin: 1rem 0rem">

  <label class="col-sm-12">Id</label>
  <div class="col-sm-12">
    <input
      type="text"
      class="form-control form-control-sm keditor-id"
      placeholder="idElemento"
      readonly
    />
  </div>
  <label class="col-sm-12 mt-3">Classes</label>
  <div class="col-sm-12">
    <input
      type="text"
      class="form-control form-control-sm custom-class"
      placeholder="Ex: classe1 classe2"
    />
    <span class="text-muted">Ex: classe1 classe2</span>
  </div>
</div>

${ANIMATIONS_FORM_LAYOUT}

<div class="form-settings-head">
  Exibição<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>

<div class="form-group row" style="margin: 1rem 0rem">
  ${TIMEDELAY_FORM_LAYOUT}

  <div class="d-flex col-sm-12 mt-3">
    <label class="col-sm-10 p-0 m-0">Ocultar no Celular</label>
    <div class="col-sm-2 p-0 m-0">
      <div class="button-switch col-sm-2">
        <input 
          type="checkbox" 
          id="switch-blue" 
          class="switch  hide-mobile" 
        />
        <label for="switch-blue" class="lbl-off"></label>
        <label for="switch-blue" class="lbl-on"></label>
      </div>
    </div>
  </div>
  <div class="d-flex col-sm-12 mt-3">
    <label class="col-sm-10 p-0 m-0">Ocultar no Computador</label>
    <div class="col-sm-2 p-0 m-0">
      <div class="button-switch col-sm-2">
        <input 
          type="checkbox" 
          id="switch-blue" 
          class="switch hide-desktop" 
        />
        <label for="switch-blue" class="lbl-off"></label>
        <label for="switch-blue" class="lbl-on"></label>
      </div>
    </div>
  </div>
</div>
`
