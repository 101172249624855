/**
 * Microcomponent to deal with Margin and Padding CSS Property. Default to Margin.
 * If you want to work with padding, you should set params with 'custom' prefix.
 * @param {*} param0 
 * @returns {*}
 */
const useInputSpacing = ({form, keditor, elementRef, customFormRef, customSpacingProp, customValueType, customCompomentName, label, fullWidth, isFirst}) => {
    
    const formRef = customFormRef || `input-margin`
    const spacingProp = customSpacingProp || `margin`
    const valueType = customValueType || `px` //[px, rem, em, %]
  
    const init = (callback) => {
        form.find(`#${formRef}`).on('change', function (event) {
            elementRef().css(spacingProp, event.target.value + valueType)
            if(callback) callback(event.target.value)
        })
    }
  
    const show = () => {
        const marginValue = parseInt(elementRef().css(spacingProp))
        form.find(`#${formRef}`).val(marginValue)
    }
  
    const template = /*html*/ `
    <div class="col-sm-${fullWidth ? 12 : 6} ${!isFirst && 'mt-3'}">
      <label for="${formRef}">${label}</label>
      <div class="input-group input-group-sm">
        <input 
            type="number" 
            name="${formRef}" 
            class="form-control" 
            id="${formRef}"
            autocomplete="off"
        />
        <div class="input-group-append">
            <span class="input-group-text">${valueType}</span>
        </div>
      </div>
    </div>   
  `
  
    return {
      componentName: customCompomentName || 'inputMargin',
      init,
      show,
      template,
    }
  }
  
  export default useInputSpacing
  