import textSpacingEventListener from '../helpers/textSpacingEventListener'

class SpacingConfig {
  #default = 'line-height-default'
  #mobile = 'line-height-mobile'
  #block = 'line-height-block'
  #dataDefault = 'data-line_height_default'
  #dataMobile = 'data-line_height_mobile'
  #dataBlock = 'data-line_height_block'

  #blocked = true

  #style = 'line-height'

  constructor({ form, style = 'line-height', keditor }) {
    this._form = form
    this._keditor = keditor
    this._element
    this.#default = `${style}-default`
    this.#mobile = `${style}-mobile`
    this.#block = `${style}-block`

    this.#dataDefault = `data-${this.#default.replace(/-/g, '_')}`
    this.#dataMobile = `data-${this.#mobile.replace(/-/g, '_')}`
    this.#dataBlock = `data-${this.#block.replace(/-/g, '_')}`
    this.#style = style
  }

  set form(newForm) {
    this._form = newForm
  }

  set element(newElement) {
    this._element = newElement
  }

  #input = ({ label, id, isMobile, target = '', isFirst = true }) => {
    return /*html*/ `
      <div class="col-sm-6 ${!isFirst ? 'mt-3' : ''}">
      <div class="d-flex">
        ${
          isMobile
            ? /*html*/ `
          <span
            id="${this.#block}"
            class="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock-config-icon" 
          >
            <i class="fa fa-lock" aria-hidden="true"></i>
          </span>
          `
            : ''
        }
        <label>${label}</label>
      </div>

        <div class="row">
          <div class="col">
            <div class="d-inline">
              <div class="">
                <div class="input-group input-group-sm">
                  <input 
                    id="${id}" 
                    type="number"
                    placeholder="Padrão" 
                    min="0"
                    data-target="${target}" 
                    step="0.1" 
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">px</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `
  }

  template(label, isFirst = true) {
    return /*html*/ `
    <h6 
      class="col-sm-12 ${!isFirst ? 'mt-3' : ''} mb-3"
      style="color: #fff; font-weight: bold; "
    >${label}</h6>
    <div class="d-flex flex-wrap" style="width: 100%">
    ${this.#input({ label: 'Computador', id: this.#default, target: this.#dataDefault })}
    ${this.#input({ label: 'Celular', id: this.#mobile, isMobile: true, target: this.#dataMobile })}
    </div>
    `
  }

  renderStyle() {
    if (!this._keditor) return
    const currentWidth = this._keditor.options.currentDeviceWidth
    textSpacingEventListener(currentWidth, this._keditor, this.#style)
  }

  init(startsToZero = false) {
    if (!this._form) return
    this._form.find(`#${this.#default}`).on('change', (e) => {
      const { value } = e.target
      const mobileValue = this.#blocked ? value : this._form.find(`#${this.#mobile}`).val()
      let val = value

      if (!startsToZero && !this._element.attr(this.#dataDefault) && val - 0.1 == 0) {
        val = (parseFloat(this._element.attr('data-font_size_default')) * 1.2).toFixed(1)
        this._form.find(`#${this.#default}`).val(val)
      }

      if (this.#blocked) {
        this._form.find(`#${this.#mobile}`).val(val)
      }

      this._element.attr({
        [this.#dataDefault]: val,
        [this.#dataMobile]: mobileValue,
      })

      this.renderStyle()
    })

    this._form.find(`#${this.#mobile}`).on('change', (e) => {
      const { value } = e.target
      let val = value

      if (!startsToZero && !this._element.attr(this.#dataMobile) && val - 0.1 == 0) {
        val = (parseFloat(this._element.attr('data-font_size_mobile')) * 1.2).toFixed(1)
        this._form.find(`#${this.#mobile}`).val(val)
      }

      this._element.attr({
        [this.#dataMobile]: val,
      })

      this.renderStyle()
    })

    this._form.find(`#${this.#block}`).on('click', (e) => {
      this.#blocked = !this.#blocked
      const mobileValue = this.#blocked
        ? this._form.find(`#${this.#default}`).val()
        : this._form.find(`#${this.#mobile}`).val()

      if (this.#blocked) this._element.css(this.#style, '')
      this._element.attr({
        [this.#dataBlock]: this.#blocked,
        [this.#dataMobile]: mobileValue,
      })
      if (this.#blocked) this._form.find(`#${this.#mobile}`).val(mobileValue)

      const icon = this._form.find(`#${this.#block}`).find('i')
      icon.toggleClass('fa-lock')
      icon.toggleClass('fa-unlock')
      this._form.find(`#${this.#mobile}`).attr('disabled', this.#blocked)

      this.renderStyle()
    })
  }

  show() {
    if (!this._form && this._element) return

    if (!this._element.attr(this.#dataDefault)) {
      this._element.attr(this.#dataDefault, '')
      this._element.css(this.#style, '')
    }
    if (!this._element.attr(this.#dataMobile)) {
      this._element.attr(this.#dataMobile, '')
    }

    this._form
      .find(`#${this.#default}`)
      .val(this._element.attr(this.#dataDefault) ? this._element.attr(this.#dataDefault) : '')

    this._form
      .find(`#${this.#mobile}`)
      .val(this._element.attr(this.#dataMobile) ? this._element.attr(this.#dataMobile) : '')

    const icon = this._form.find(`#${this.#block}`).find('i')
    if (!this._element.attr(this.#dataBlock) || JSON.parse(this._element.attr(this.#dataBlock))) {
      this.#blocked = true
      icon.addClass('fa-lock')
      icon.removeClass('fa-unlock')
    } else {
      this.#blocked = false
      icon.removeClass('fa-lock')
      icon.addClass('fa-unlock')
    }

    this._form.find(`#${this.#mobile}`).attr('disabled', this.#blocked)
  }
}

function useTextSpacing({ form, keditor }) {
  const componentName = 'textSpacing'
  const lineHeightComponent = new SpacingConfig({ form, keditor })
  const letterSpacingComponent = new SpacingConfig({ form, style: 'letter-spacing', keditor })

  const init = (elementRef) => {
    lineHeightComponent.element = elementRef()
    lineHeightComponent.form = form
    lineHeightComponent.init()

    letterSpacingComponent.element = elementRef()
    letterSpacingComponent.form = form
    letterSpacingComponent.init(true)
  }

  const show = (elementRef) => {
    lineHeightComponent.element = elementRef()
    lineHeightComponent.show()

    letterSpacingComponent.element = elementRef()
    letterSpacingComponent.show()
  }

  const template = /*html*/ `
  ${lineHeightComponent.template('Entre linhas')}
  ${letterSpacingComponent.template('Entre letras', false)}
  `

  return {
    template,
    componentName,
    init,
    show,
  }
}

export default useTextSpacing
