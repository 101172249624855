
const useStickyPosition = ({form, keditor, elementRef, customFormRef, customOptions, label, fullWidth, isFirst}) => {

  const STICKYPOSATTR = `data-sticky-position`
  const formRef = customFormRef || `sticky-position-editor`
  const options = customOptions || [
    {
      name: 'Superior Esquerdo',
      value: 'top-left',
    },
    {
      name: 'Superior Direito',
      value: 'top-right',
    },
    {
      name: 'Inferior Esquerdo',
      value: 'bottom-left',
    },
    {
      name: 'Inferior Direito',
      value: 'bottom-right',
    },
  ]

  const init = () => {
    form.find(`#${formRef}`).on('change', (event) => {
      elementRef().attr(STICKYPOSATTR, event.target.value)
    })
  }

  const show = () => {
    const curval = elementRef().attr(STICKYPOSATTR)
    form.find(`#${formRef}`).val(
      options.find((el) => el.value === curval) ? curval : 'top-left' 
    )
  }

  const renderOptions = () => {
    let acc = ''
    options.forEach((element) => {
      acc += `<option value="${element.value || element.name}">${element.name}</option>`
    })
    return acc
  }

  const template = /*html*/ `
    <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderOptions()}
      </select>
    </div>   
  `

  return {
    componentName: 'stickyPosition',
    init,
    show,
    template,
  }
}

export default useStickyPosition