import { useSelectAnimationType, useSelectAnimationClasses } from '../../microComponents'

const animationTypeSelect = useSelectAnimationType({
  label: 'Quando animar este elemento?',
  fullWidth: true,
  isFirst: true,
})

const animationDelaySelect = useSelectAnimationClasses({
    label: 'Atraso',
    customFormRef: 'animation-delay-editor',
    customOptions: [
        { name: 'Nenhum', value: ''},
        { name: '0.5s', value: 'delay-1'},
        { name: '1s', value: 'delay-2'},
        { name: '1.5s', value: 'delay-3'},
        { name: '2s', value: 'delay-4'},
    ]
})

const animationAppearSelect = useSelectAnimationClasses({
    label: 'Animação',
    customFormRef: 'animation-appear-editor',
    customOptions: [
        { name: 'Desvanecer', value: 'mfaslide mffade-up'},
        { name: 'Pop', value: 'mfpop'},
        { name: 'Névoa', value: 'mfblur'},
        { name: 'Crescer', value: 'mfgrow'},
        { name: 'Virar', value: 'mfspin'},
        { name: 'Deslize Esquerdo', value: 'mfaslide mfslide-left'},
        { name: 'Deslize Direito', value: 'mfaslide mfslide-right'},
    ]
})

const animationSelect = useSelectAnimationClasses({
    label: 'Animação',
})



export const ANIMATIONS_FORM_LAYOUT =  `<div class="form-settings-head">
Animação<span><i class="fa fa-caret-down" aria-hidden="true"></i></span>
</div>
<div class="form-group" style="margin: 1rem 0rem">
    ${animationTypeSelect.template}
    <div id="wrap-animations-options" style="display: none;">
        ${animationSelect.template}
    </div>
    <div id="wrap-animations-appear-options" style="display: none;">
        <div class="d-flex flex-wrap">
            ${animationAppearSelect.template}
            ${animationDelaySelect.template}
        </div>
    </div>
    <div class="d-flex flex-wrap">
    <p class="text-muted col-sm-12 mt-3" style="font-size: 12px !important; font-style: italic;">
    * A mudança dessa configuração só será possível vê-la na página final publicada.
    </p>
    </div>
</div>`;