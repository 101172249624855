const useInputDateTime = ({ form, label, customFormRef, elementRef, fullWidth = true, isFirst = false, type = 'date'}) => {
    const formRef = customFormRef || `input-date-time`
    const element = form && form.find(`#${formRef}`)
    
    const typeOptions = ['datetime', 'date', 'time']
    const dateTimeType = typeOptions.find((el) => el == type) ? type : 'date'
  
    function datetimeValidation(type, value) {
      switch (type) {
        case 'datetime':
            if(value.match(/^[0-9]{4}-[0-1][0-9]-[0-9]{2}\s[0-9]{2}:[0-9]{2}$/)) return true;
            break;
        case 'date':
            if(value.match(/^[0-9]{4}-[0-1][0-9]-[0-9]{2}$/)) return true;
            break;
        case 'time':
            if(value.match(/^[0-9]{2}:[0-9]{2}$/)) return true;
            break;
        default:
            return null
      }
      return false
    }

    const init = ({callback}) => {
      element.on('change', function (event) {
        const { value } = event.target
        
        if(datetimeValidation(dateTimeType,value)){
          element.removeClass("is-invalid")
          if (typeof callback === "function") callback(value, elementRef)
        }else{
          element.addClass("is-invalid")
        }
        
      })
    }
  
    const show = ({customValue, callback}) => {
        const value = customValue || (typeof callback === "function" && callback(elementRef)) || null

        // //Default value? -> Isso não vai ser salvo... talvez seja melhor não.
        // if (!value && dateTimeType == 'date') {
        //     value = new Date().toISOString().split('T')[0]
        // }else if(!value && dateTimeType == 'time'){
        //     value = new Date().toLocaleTimeString('en-GB', {hour: "numeric", minute: "numeric"});
        // }else if (!value) {
        //     value = new Date().toLocaleString('en-GB').replace(',','');
        // }
  
        if(value) element.val(value);
    }
  
    const template = /*html*/ `
    <div class="col-sm-${fullWidth ? 12 : 6} ${!isFirst && 'mt-3'}"">
      <label for="${formRef}">${label}</label>
      <input 
        type="${dateTimeType != 'datetime' ? dateTimeType : 'datetime-local'}" 
        name="${formRef}" 
        class="form-control" 
        id="${formRef}"
        ${dateTimeType == "date" && 'min="1970-01-01"'}
      />
    </div>   
  `
  
    return {
      componentName: 'inputDateTime',
      init,
      show,
      template,
      elementRef
    }
  }
  
  export default useInputDateTime
  