const useFontStyle = ({
  form,
  keditor,
  component,
  label,
  customFormRef,
  elementRef
}) => {
  const formRef = customFormRef || `font-weight-editor`
  const element = form && form.find(`#${formRef}`)
  const options = [
    {
        name: 'normal',
        value: '400',
    },
    {
        name: 'negrito',
        value: '700',
    },
]

  const init = () => {
      element.on('change', (event) => {
          elementRef().css('font-weight', event.target.value)
      })
  }

  const show = () => {
      const fontWeight = elementRef().css('font-weight')
      
      const option = options.filter(el => el.value === fontWeight)[0]
      element.val(option && option.value)
  }

  const renderOptions = () => {
      let acc = ''        

      options.forEach((element) => {
          acc += `<option value="${element.value || element.name}">${element.name}</option>`
      })

      return acc
  }

  const template = /*html*/ `
  <div class="col-sm-6 mt-3">
    <label for="${formRef}">${label}</label>
    <select id="${formRef}" class="form-control">
      ${renderOptions()}
    </select>
  </div>   
`

  return {
      componentName: 'fontStyle',
      init,
      show,
      template,
  }
}

export default useFontStyle
