const useImageFit = ({
    form, keditor, elementRef, customFormRef,
    label, fullWidth, isFirst,
    customOptions, isBgImg = true
  }) => {
    const formRef = customFormRef || 'background-size'
    const element = form && form.find(`#${formRef}`)
    const optionsList = customOptions || [
      { value: 'auto', label: 'Auto' },
      { value: 'contain', label: 'Conter' },
      { value: 'cover', label: 'Cobrir' },
    ]

    const init = (callback) => {
        element.on('change', ({ target: { value } } = e) => {
          elementRef().css({ [isBgImg ? 'backgroundSize' : 'objectFit']: value })
          
          if (typeof callback === "function") callback(value)
        })
      }
    
      const show = () => {
        element.val(
          elementRef()
            .css(isBgImg ? 'backgroundSize' : 'objectFit')
        )
      }
    
      const renderOptions = () => {
        let options = ''
        optionsList.forEach((opt) => {
          options += `<option value="${opt.value}">${opt.label}</option>`
        })
        return options
      }
    
      const template = /*html*/ `
        <div class="tab-content col-sm-${fullWidth ? 12 : 6} ${isFirst ? '' : 'mt-3'}">
          <label for="${formRef}">${label}</label>
          <select id="${formRef}" class="form-control">
            ${renderOptions()}
          </select>
        </div>
        `

      const tabTemplate = /*html*/ ``
    
      return {
        componentName: 'imageFitSelector',
        init,
        show,
        template,
      }
    }
  
  export default useImageFit
  