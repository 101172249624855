export default function (keditor, elementRefId, newValue) {
    if (!keditor) return
    
    //Manage the elementRef sticky_function call on footer_system
    const fs_content_stickyArr = keditor.options.contentScripts.footer_system.content.stickyArr
    const index = fs_content_stickyArr.indexOf(elementRefId)
    if(newValue === 'none'){
      //Remove if found
      if (index > -1) {
        fs_content_stickyArr.splice(index, 1)
      }
    }else{
      //Add if not added yet
      if (index == -1) {
        fs_content_stickyArr.push(elementRefId)
      }
    }

  }
  