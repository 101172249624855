import CSS_CLASS from '../../../../keditor/constants/cssClass'

export function navTab({
  customTabs = [
    {
      id: 'background-color',
      tabName: 'Background',
      tabContent: '',
      isActive: false,
    },
  ],
}) {
  let tabs = '',
    content = ''
  customTabs.forEach((tab, index) => {
    tabs += /*html*/ `
          <li class="${CSS_CLASS.BOOTSTRAP_NAV_ITEM} ${CSS_CLASS.BOOTSTRAP_NAV_ITEM_33}">
            <a 
              class="${CSS_CLASS.BOOTSTRAP_NAV_LINK} ${tab.isActive && 'active'}" 
              id="form-${tab.id}" 
              data-toggle="tab" 
              href="#${tab.id}"
              role="tab" 
              aria-controls="form" 
              aria-selected="true"
            >
              ${tab.tabName}
            </a>
          </li>
        `
    content += /*html*/ `
          <div 
            class="tab-pane fade show ${tab.id} ${tab.isActive && 'active'}" 
            id="${tab.id}" 
            role="tab" 
            aria-labelledby="form-${tab.id}"
          >
            ${tab.tabContent || 'Sem conteudo'}
          </div>
        `
  })
  return /*html*/ `
  <ul class="${CSS_CLASS.BOOTSTRAP_NAV} ${CSS_CLASS.BOOTSTRAP_NAV_TABS}" role="tablist">
    ${tabs}
  </ul>
  <div class="tab-content mt-3 mx-2 bg-config" id="pills-tabContent">
    ${content}
  </div>
  `
}
