const usePosition = ({ form, keditor, component, elementRef, label, customOptions, customFormRef, fullWidth }) => {
  const formRef = customFormRef || 'content-position'
  const element = form && form.find(`#${formRef}`)
  const positionList = customOptions || [
    { value: 'info-top', label: 'Cima' },
    { value: 'info-bottom', label: 'Baixo' },
  ]

  const init = (elementTarget, position) => {
    element.on('change', ({ target: { value } } = e) => {
      const elementParentRef = elementRef()
      const elementClone = elementTarget().clone()

      elementTarget().remove()
      elementClone.appendTo(elementParentRef.find(`.${value}-${position}`))
    })
  }

  const show = (elementTarget, position) => {
    const elementPosition = elementTarget().parent().attr('class').replace(`-${position}`, '')
    element.val(elementPosition)
  }

  const renderFontOptions = () => {
    let fonts = ''
    positionList.forEach((font) => {
      fonts += `<option value="${font.value}">${font.label}</option>`
    })
    return fonts
  }

  const template = /*html*/ `
    <div class="tab-content col-sm-${fullWidth ? 12 : 6} mt-3">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderFontOptions()}
      </select>
    </div>
    `

  return {
    componentName: 'positionSelect',
    init,
    show,
    template,
  }
}

export default usePosition
