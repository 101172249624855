import { useSelectAnimationType, useSelectAnimationClasses } from '../../microComponents'

export function initAnimations (aform, akeditor) {

    function getElementRef () {
        let curElement = akeditor.getSettingComponent()
        ? akeditor.getSettingComponent() : akeditor.getSettingContainer();

        return curElement;
    }

    function getFinalElementRef () {
        let curElement = akeditor.getSettingComponent()
        ? akeditor.getSettingComponent() : akeditor.getSettingContainer();

        return curElement.children().first().children().first();
    }
    
    useSelectAnimationType({
        form: aform,
        keditor: akeditor,
        elementRef: getElementRef,
    }).init()
    useSelectAnimationClasses({
        form: aform,
        keditor: akeditor,
        elementRef: getFinalElementRef,
    }).init()
    useSelectAnimationClasses({
        form: aform,
        keditor: akeditor,
        customFormRef: 'animation-delay-editor',
        elementRef: getFinalElementRef,
    }).init()
    useSelectAnimationClasses({
        form: aform,
        keditor: akeditor,
        customFormRef: 'animation-appear-editor',
        elementRef: getFinalElementRef,
    }).init()
}

export function showAnimations (aform, akeditor) {

    function getElementRef () {
        let curElement = akeditor.getSettingComponent()
        ? akeditor.getSettingComponent() : akeditor.getSettingContainer();

        return curElement;
    }

    function getFinalElementRef () {
        let curElement = akeditor.getSettingComponent()
        ? akeditor.getSettingComponent() : akeditor.getSettingContainer();

        return curElement.children().first().children().first();
    }
    
    useSelectAnimationType({
        form: aform,
        keditor: akeditor,
        elementRef: getElementRef,
    }).show()
    useSelectAnimationClasses({
        form: aform,
        keditor: akeditor,
        elementRef: getFinalElementRef,
    }).show('mfpulse')
    useSelectAnimationClasses({
        form: aform,
        keditor: akeditor,
        customFormRef: 'animation-delay-editor',
        elementRef: getFinalElementRef,
    }).show()
    useSelectAnimationClasses({
        form: aform,
        keditor: akeditor,
        customFormRef: 'animation-appear-editor',
        elementRef: getFinalElementRef,
    }).show('mfblur')
}