const useAlignment = ({ form, keditor, component, elementRef, label, customOptions, customFormRef }) => {
  const formRef = customFormRef || 'content-alignment'
  const element = form && form.find(`#${formRef}`)
  const positionList = customOptions || [
    { value: 'left', label: 'Esquerda' },
    { value: 'center', label: 'Centro' },
    { value: 'right', label: 'Direita' },
  ]

  const init = () => {
    element.on('change', ({ target: { value } } = e) => {
      elementRef().css({ textAlign: value })
    })
  }

  const show = () => {
    element.val(elementRef().css('text-align'))
  }

  const renderFontOptions = () => {
    let fonts = ''
    positionList.forEach((font) => {
      fonts += `<option value="${font.value}">${font.label}</option>`
    })
    return fonts
  }

  const template = /*html*/ `
    <div class="tab-content col-sm-6 mt-3">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderFontOptions()}
      </select>
    </div>
    `

  return {
    componentName: 'alignmentSelect',
    init,
    show,
    template,
  }
}

export default useAlignment
