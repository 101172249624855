
export default function (form, keditor) {

    form.find("#form-time-delay").on("change", function () {
        let curElement = keditor.getSettingComponent()
          ? keditor.getSettingComponent() : keditor.getSettingContainer();

        let finalElement = curElement.children().first().children().first();

        if (this.value == 0 || this.value == "") {
          delete keditor.options.contentScripts.footer_system.content.timeDelayArr[curElement.attr("id")];
          finalElement.removeClass("delayed");
        }else{
          keditor.options.contentScripts.footer_system.content.timeDelayArr[curElement.attr("id")] = this.value

          if (!finalElement.hasClass("delayed")) {
            finalElement.addClass("delayed");
          }
        }
    });
}