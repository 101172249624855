/**
 * Microcomponent with toggle formart to do generic functions
 * @param {*} param0 
 * @returns {*}
 */
 const useToggleGeneric = ({
    form,
    keditor,
    elementRef,
    customFormRef,
    defaultChecked = false,
    isFirst = false,
    label = 'Mostrar',
  }) => {
    const formRef = customFormRef || 'toggle'
    const element = (target) => form && form.find(`#${target}`)
  
    const init = ({callback, cssPropertyName, valueChecked, valueUnchecked}) => { //propriedade & valor (when check and when uncheck)
      element(formRef).on('click', function ({ target } = e) {
        const isChecked = $(target).prop('checked')
  
        if (typeof callback === "function") callback(isChecked, elementRef)
        
        if(cssPropertyName && valueChecked && valueUnchecked) elementRef().css(cssPropertyName, isChecked ? valueChecked : valueUnchecked)
        
      })
    }
  
    /**
     * @param {function} callback
     * @param {boolean} value boolean value to set the element
     */
    const show = ({callback, value}) => {

      element(formRef).prop('checked', (value !== null && typeof value == 'boolean') ? value : elementRef().is(':visible'))
  
      if (typeof callback === "function") callback(element(formRef).prop('checked'))
    }
  
    const template = /*html*/ `
      <div class="d-flex col-sm-12 ${!isFirst ? 'mt-3' : ''}">
        <label class="col-sm-10 p-0 m-0">${label}</label>
        <div class="col-sm-2 p-0 m-0">
          <div class="button-switch col-sm-2">
            <input 
                type="checkbox" 
                id="${formRef}" 
                class="switch"
                checked="${defaultChecked}" 
            />
            <label for="${formRef}" class="lbl-off"></label>
            <label for="${formRef}" class="lbl-on"></label>
          </div>
        </div>
      </div>
      `
  
    return {
      componentName: 'toggleGeneric',
      init,
      show,
      template,
    }
  }
  
  export default useToggleGeneric
  