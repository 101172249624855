import { SET_CONTAINER_BACKGROUND } from '../../../../constants'
import { onSetImageContainer } from '../../../../keditor/modal/gallery/methods'
import initModalPhoto from '../../../../keditor/modal/initModalPhoto'
import ColorPicker from '../../../helpers/colorPicker'
import { getRgbish } from '../helpers'
const colorPicker = new ColorPicker()

export default class ContainerComponent {
  #elements = {
    gradientOrientation: {
      selector: '#gradient-direction',
      data: null,
    },
    gradientInitColor: {
      selector: '#gradient-bg-init',
      data: null,
    },
    gradientInitPosition: {
      selector: '#range-init',
      data: null,
    },
    gradientFinalPosition: {
      selector: '#range-final',
      data: null,
    },
    gradientFinalColor: {
      selector: '#gradient-bg-final',
      data: null,
    },
    invertColor: {
      selector: '#invert-color',
      data: null,
    },
    backgroundColor: {
      selector: '#input-background',
      data: null,
    },
    backgroundSize: {
      selector: '#background-size',
      data: null,
    },
    backgroundPosition: {
      selector: '#background-position',
      data: null,
    },
    backgroundRepeat: {
      selector: '#background-repeat',
      data: null,
    },
    clearBackground: {
      selector: '#clear-bg',
      data: null,
    },
    clearGradient: {
      selector: '#clear-bg-gradient',
      data: null,
    },
    clearImage: {
      selector: '#clear-bg-image',
      data: null,
    },
    openGallery: {
      selector: '#photo-galery, #photo-galery-2',
      data: null,
    },
  }
  #background = {
    elementId: '',
    color: 'rgba(0,0,0,0)',
    gradient: {
      init: {
        color: 'rgba(0,0,0,0)',
        position: 0,
      },
      final: {
        color: 'rgba(0,0,0,0)',
        position: 100,
      },
      orientation: 'to bottom',
    },
    img: {
      src: '',
      repeat: 'no-repeat',
      position: '50% 0%',
      size: 'auto',
    },
    colorInverted: false,
  }

  constructor() {
    this._form
    this._keditor
    this._container
  }

  set container(newContainer) {
    this._container = newContainer
  }

  set keditor(keditor) {
    this._keditor = keditor
  }

  set form(form) {
    this._form = form

    Object.keys(this.#elements).map((key) => {
      this.#elements[key].data = this._form.find(this.#elements[key].selector)
    })
  }

  // METHODS
  getRef() {
    return this._keditor && this._keditor.getSettingContainer()
      ? this._keditor.getSettingContainer().find('.keditor-container-inner').first().children().first()
      : this._container.find('.keditor-container-inner').first().children().first()
  }

  create() {
    const container = this.getRef()

    const marginDefault = container.data('margin_default')
    const marginMobile = container.data('margin_mobile')

    if (!marginDefault && !marginMobile) {
      const marginTop = container.css('marginTop').replace('px', '')
      container.attr({
        'data-margin_default': marginTop,
        'data-margin_mobile': marginTop,
        'data-spacing-block': true,
      })
    }

    this.#background = this.getObject()
    container.attr('data-background', JSON.stringify(this.#background))
  }

  init() {
    if (!this._form) return

    //Image Callback
    onSetImageContainer((imageSelected) =>
    {
      this.#background.img.src = imageSelected
    });

    // COLOR CONFIG
    // -- color
    colorPicker.init(
      this._form,
      (color) => {
        const container = this.getRef()
        container.css('backgroundColor', color)
        this.setObject({ target: 'color', value: color })
      },
      this.#elements.backgroundColor.selector
    )
    // -- gradient color init
    colorPicker.init(
      this._form,
      (color) => {
        const container = this.getRef()
        container.css(
          'backgroundImage',
          this.setBackgroundImageStyle({ subTarget: 'init', detailTarget: 'color', value: color })
        )
      },
      this.#elements.gradientInitColor.selector
    )
    // -- gradient color final
    colorPicker.init(
      this._form,
      (color) => {
        const container = this.getRef()
        container.css(
          'backgroundImage',
          this.setBackgroundImageStyle({ subTarget: 'final', detailTarget: 'color', value: color })
        )
      },
      this.#elements.gradientFinalColor.selector
    )

    // GRADIENT
    this.#elements.gradientOrientation.data.find('.nav-item-custom').on('click', (event) => {
      const container = this.getRef()
      container.css(
        'backgroundImage',
        this.setBackgroundImageStyle({ subTarget: 'orientation', value: $(event.target).attr('data-value') })
      )
    })

    this.#elements.gradientInitPosition.data.on('change', (event) => {
      const { value } = event.target
      const container = this.getRef()
      container.css(
        'backgroundImage',
        this.setBackgroundImageStyle({ subTarget: 'init', detailTarget: 'position', value })
      )
    })

    this.#elements.gradientFinalPosition.data.on('change', (event) => {
      const { value } = event.target
      const container = this.getRef()
      container.css(
        'backgroundImage',
        this.setBackgroundImageStyle({ subTarget: 'final', detailTarget: 'position', value: 100 - value })
      )
    })

    this.#elements.invertColor.data.on('click', (event) => {
      const isChecked = $(event.target).prop('checked')
      const container = this.getRef()

      this.setObject({ target: 'colorInverted', value: isChecked })
      const finalColor = this.#background.gradient.final.color
      const initColor = this.#background.gradient.init.color

      this.setObject({ target: 'gradient', subTarget: 'init', detailTarget: 'color', value: finalColor })
      this.setObject({ target: 'gradient', subTarget: 'final', detailTarget: 'color', value: initColor })

      const url = this.#background.img.src ? `, url(${this.#background.img.src})` : ''
      const { init, final, orientation } = this.#background.gradient
      const style = `linear-gradient(${orientation}, ${init.color} ${init.position}%, ${final.color} ${final.position}%)`

      container.css('backgroundImage', `${style}${url}`)
    })

    // IMAGE
    this.#elements.backgroundSize.data.find('.nav-item-custom').on('click', (event) => {
      const container = this.getRef()
      this.setObject({ target: 'img', subTarget: 'size', value: $(event.target).attr('data-value') })
      container.css('backgroundSize', $(event.target).attr('data-value'))
    })

    this.#elements.backgroundPosition.data.find('.nav-item-custom').on('click', (event) => {
      const container = this.getRef()
      this.setObject({ target: 'img', subTarget: 'position', value: $(event.target).attr('data-value') })
      container.css('backgroundPosition', $(event.target).attr('data-value'))
    })

    this.#elements.backgroundRepeat.data.find('.nav-item-custom').on('click', (event) => {
      const container = this.getRef()
      this.setObject({ target: 'img', subTarget: 'repeat', value: $(event.target).attr('data-value') })
      container.css('backgroundRepeat', $(event.target).attr('data-value'))
    })

    this._form.on('click', this.#elements.openGallery.selector, (event) => {
      this._keditor.options.locale.modalImage.isContainer = true
      this._keditor.options.locale.modalImage.isIframe = false
      this._keditor.options.locale.modalImage.target = this.getRef()
      this._keditor.options.locale.modalImage.form = this._form
    })

    // CLEAR
    this.#elements.clearBackground.data.on('click', (event) => {
      const clearStyle = this.clear('color')
      const container = this.getRef()
      container.css('backgroundColor', clearStyle)
    })

    this.#elements.clearGradient.data.on('click', (event) => {
      const clearStyle = this.clear('gradient')
      const container = this.getRef()
      container.css('backgroundImage', clearStyle)
    })

    this.#elements.clearImage.data.on('click', (event) => {
      const clearStyle = this.clear('img')
      const container = this.getRef()
      container.css('backgroundImage', clearStyle)
    })
  }

  show() {
    if (!this._container || !this._form) return

    this.#background = this.getObject()

    colorPicker.show(this._form, this.#background.color, this.#elements.backgroundColor.selector)
    colorPicker.show(this._form, this.#background.gradient.init.color, this.#elements.gradientInitColor.selector)
    colorPicker.show(this._form, this.#background.gradient.final.color, this.#elements.gradientFinalColor.selector)

    // GRADIENT
    this.#elements.gradientInitPosition.data.val(this.#background.gradient.init.position)
    this.#elements.gradientFinalPosition.data.val(100 - this.#background.gradient.final.position)
    this.#elements.invertColor.data.prop('checked', this.#background.colorInverted)

    this.#elements.gradientOrientation.data.find('.nav-item-custom').removeClass('active')
    this.#elements.gradientOrientation.data
      .find(`.nav-item-custom[data-value='${this.#background.gradient.orientation}']`)
      .addClass('active')

    // IMAGE
    this.#elements.backgroundSize.data.find('.nav-item-custom').removeClass('active')
    this.#elements.backgroundSize.data
      .find(`.nav-item-custom[data-value='${this.#background.img.size}']`)
      .addClass('active')

    this.#elements.backgroundRepeat.data.find('.nav-item-custom').removeClass('active')
    this.#elements.backgroundRepeat.data
      .find(`.nav-item-custom[data-value='${this.#background.img.repeat}']`)
      .addClass('active')

    this.#elements.backgroundPosition.data.find('.nav-item-custom').removeClass('active')
    this.#elements.backgroundPosition.data
      .find(`.nav-item-custom[data-value='${this.#background.img.position}']`)
      .addClass('active')

    // CHANGE VIEW
    if (this.#background.img.src) {
      this._form.find('.change-image-button').css({ backgroundImage: `url(${this.#background.img.src})` })
      this._form.find('.upload-button').hide()
      this._form.find('.change-image-button').show()
    } else {
      this._form.find('.upload-button').show()
      this._form.find('.change-image-button').hide()
    }
  }

  clear(target) {
    const clearObject = {
      color: 'rgba(0,0,0,0)',
      gradient: {
        init: {
          color: 'rgba(0,0,0,0)',
          position: 0,
        },
        final: {
          color: 'rgba(0,0,0,0)',
          position: 100,
        },
        orientation: 'to bottom',
      },
      img: {
        src: '',
        repeat: 'no-repeat',
        position: '0% 0%',
        size: 'auto',
      },
    }
    this.#background[target] = clearObject[target]

    switch (target) {
      case 'color':
        colorPicker.show(this._form, clearObject.color, this.#elements.backgroundColor.selector)
        this.setObject({ target: 'color', value: clearObject.color })
        return clearObject.color
      case 'gradient':
        this.#background['colorInverted'] = false
        this.#elements.gradientInitPosition.data.val(clearObject.gradient.init.position)
        this.#elements.gradientFinalPosition.data.val(100 - clearObject.gradient.final.position)
        this.#elements.invertColor.data.prop('checked', false)

        this.#elements.gradientOrientation.data.find('.nav-item-custom').removeClass('active')
        this.#elements.gradientOrientation.data
          .find(`.nav-item-custom[data-value='${clearObject.gradient.orientation}']`)
          .addClass('active')
        colorPicker.show(this._form, clearObject.gradient.init.color, this.#elements.gradientInitColor.selector)
        colorPicker.show(this._form, clearObject.gradient.final.color, this.#elements.gradientFinalColor.selector)

        this.setObject({ target: 'gradient', value: clearObject.gradient })

        return `url(${this.#background.img.src})`
      case 'img':
        this.#elements.backgroundSize.data.find('.nav-item-custom').removeClass('active')
        this.#elements.backgroundSize.data
          .find(`.nav-item-custom[data-value='${clearObject.img.size}']`)
          .addClass('active')

        this.#elements.backgroundRepeat.data.find('.nav-item-custom').removeClass('active')
        this.#elements.backgroundRepeat.data
          .find(`.nav-item-custom[data-value='${clearObject.img.repeat}']`)
          .addClass('active')

        this.#elements.backgroundPosition.data.find('.nav-item-custom').removeClass('active')
        this.#elements.backgroundPosition.data
          .find(`.nav-item-custom[data-value='${clearObject.img.position}']`)
          .addClass('active')
        const { init, final, orientation } = this.getObject().gradient
        this._form.find('.upload-button').show()
        this._form.find('.change-image-button').hide()

        this.setObject({ target: 'img', value: clearObject.img })

        return `linear-gradient(${orientation}, ${init.color} ${init.position}%, ${final.color} ${final.position}%)`
      default:
        return null
    }
  }

  setObject({ target, subTarget, detailTarget, value }) {
    const container = this.getRef()
    
    if (subTarget)
    {
      if (detailTarget) this.#background[target][subTarget][detailTarget] = value
      else this.#background[target][subTarget] = value
    } else this.#background[target] = value

    container.attr('data-background', JSON.stringify(this.#background))

    this.updateBackgoundImgProperty(container)
  }

  setBackgroundImageStyle({ target = 'gradient', subTarget, detailTarget, value }) {
    this.setObject({ target, subTarget, detailTarget, value })

    let url = this.#background.img.src ? `, url(${this.#background.img.src})` : ''

    //Force to use color style if it is a color or gradient
    if (target === 'color')
    {
      url = ''
    }
    
    const { init, final, orientation } = this.#background.gradient
    const style = `linear-gradient(${orientation}, ${init.color} ${init.position}%, ${final.color} ${final.position}%)`

    return `${style}${url}`
  }

  updateBackgoundImgProperty(container) {
    if (!container.attr('data-background')) {
      this.#background.img.src = 
        container.css('backgroundImage').split(', url')[1] ? image[1].replace('("', '').replace('")', '') : ''
    }else{
      const bgData = JSON.parse(container.attr('data-background'))
      this.#background.img.src = bgData.img.src
    }
  }

  getObject() {
    const container = this.getRef()
    if (!container.attr('data-background')) {
      const image = container.css('backgroundImage').split(', url')
      const gradient = getRgbish(image[0]).colors
      const auxObject = {
        elementId: container.attr('id'),
        color: container.css('backgroundColor'),
        gradient: {
          init: {
            color: gradient[0].color,
            position: gradient[0].range,
          },
          final: {
            color: gradient[1].color,
            position: gradient[1].range,
          },
          orientation: 'to bottom',
        },
        img: {
          src: image[1] ? image[1].replace('("', '').replace('")', '') : '',
          repeat: container.css('backgroundRepeat'),
          position: container.css('backgroundPosition'),
          size: container.css('backgroundSize'),
        },
        colorInverted: false,
      }

      return auxObject
    }
    return JSON.parse(container.attr('data-background'))
  }
}
