import ColorPicker from '../helpers/colorPicker'
const colorPicker = new ColorPicker()

const useBackgroundColor = ({ form, keditor, component, label, elementRef, customFormRef, isFirst = false, fullWidth = false }) => {
  const formRef = customFormRef || `background-color-editor`

  const init = (callback = null) => {
    //Cor do texto
    colorPicker.init(
      form,
      (color) => {
        elementRef().css(`background-color`, `${color}`)
        if (typeof callback === "function") callback(color)
      },
      `#${formRef}`
    )
  }

  const show = () => {
    colorPicker.show(form, elementRef().css('background-color'), `#${formRef}`)
  }

  const template = /*html*/ `
  <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
    <label for="${formRef}">${label}</label>
    <input data-id="#${formRef}" class="color-picker-component form-control" />
  </div>   
  `

  return {
    componentName: 'backgroundColor',
    init,
    show,
    template,
  }
}

export default useBackgroundColor
