export default function (keditor, elementRefId, removeAnim = false) {
    if (!keditor) return
    
    //Manage the elementRef sticky_function call on footer_system
    const fs_content_animateArr = keditor.options.contentScripts.footer_system.content.animateArr
    const index = fs_content_animateArr.indexOf(elementRefId)
    if(removeAnim){
      //Remove if found
      if (index > -1) {
        fs_content_animateArr.splice(index, 1)
      }
    }else{
      //Add if not added yet
      if (index == -1) {
        fs_content_animateArr.push(elementRefId)
      }
    }

  }

export function hasAnimateWA(keditor, elementRefId){
    if (!keditor) return false

    const fs_content_animateArr = keditor.options.contentScripts.footer_system.content.animateArr
    const index = fs_content_animateArr.indexOf(elementRefId)
    
    return (index > -1) ? true : false
}
  