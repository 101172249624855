import ColorPicker from '../helpers/colorPicker'
const colorPicker = new ColorPicker()

const useFontColor = ({ form, keditor, component, label, elementRef, customFormRef }) => {
  const formRef = customFormRef || `font-color-editor`

  const init = () => {
    //Cor do texto
    colorPicker.init(
      form,
      (color) => {
          elementRef().css(`color`, `${color}`)
      },
      `#${formRef}`
    )
  }

  const show = () => {
    // const elementDOM = elementRef().length > 1 ? elementRef()[0] : elementRef()
    colorPicker.show(
      form,
      elementRef().css('color'),
      `#${formRef}`
    )
  }

  const template = /*html*/ `
  <div class="col-sm-6 mt-3">
    <label for="${formRef}">${label}</label>
    <div class="input-group">
      <input data-id="#${formRef}" class="color-picker-component form-control" />
    </div>
  </div>   
  `

  return {
      componentName: 'fontColor',
      init,
      show,
      template,
  }
}

export default useFontColor
