import initModalPhoto from '../../keditor/modal/initModalPhoto'
import { IMAGE_PANEL, IMAGE_TARGET } from '../containers/photo/constant'
import ColorPicker from '../helpers/colorPicker'
const colorPicker = new ColorPicker()

const useImageSelect = ({ form, keditor, component, label, customFormRef, elementRef, onlyGallery }) => {
  const formRef = customFormRef || {
    gallery: 'photo-gallery',
    url: 'external-url',
    alt: 'image-alt',
  }
  const showImageToggle = 'showImageToggle'
  const element = (target) => form && form.find(`#${target}`)

  const init = () => {
    element(formRef.gallery).on('click', (_) => {
      const painel = elementRef().find(IMAGE_PANEL)
      initModalPhoto({
        modalId: 'modal-img-galery',
        page: keditor,
        target: painel,
        form,
      })
    })

    element(showImageToggle).on('click', ({ target } = e) => {
      const isChecked = $(target).prop('checked')
      const painel = elementRef().find(IMAGE_PANEL)
      const imagem = painel.find(IMAGE_TARGET)

      if (!isChecked) {
        painel.removeClass('d-flex d-inline')
        painel.addClass('d-none')

        form.find('.photo-config').css('display', 'none')
        form.find('.border-config').css('display', 'none')
        form.find('.config-alert').show()
        return
      }
      painel.removeClass('d-none')
      painel.addClass('d-flex d-inline')
      imagem.attr('src') && painel.find('.demo-photo').css({ display: 'none' })
      form.find('#photo-width').val(imagem.css('width').replace('px', ''))

      if (!painel.find('.demo-photo').is(':visible')) {
        form.find('.photo-config').css('display', 'block')
        form.find('.border-config').css('display', 'flex')
        form.find('.config-alert').hide()
      }

      painel.find('.demo-photo').is(':visible') ? painel.removeClass('d-inline') : painel.removeClass('d-flex')
    })

    element(formRef.alt).on('keyup', ({ target: { value } } = e) => {
      elementRef().find(IMAGE_TARGET).attr('alt', value)
    })

    element(formRef.url).on('change', function ({ target: { value } } = e) {
      const painel = elementRef().find(IMAGE_PANEL)
      const imagem = elementRef().find(IMAGE_TARGET)
      if (value.length) {
        imagem.css({ display: 'inline' })
        imagem.attr({ src: value, 'data-cke-saved-src': value })

        if (painel.find('.demo-photo').is(':visible')) {
          painel.removeClass('d-flex justify-content-center')
          painel.css('text-align', 'center')
          painel.find('.demo-photo').css({ display: 'none' })
        }

        form.find('.photo-config').css('display', 'none')
        form.find('.border-config').css('display', 'none')
        form.find('.config-alert').show()

        form.find('.loading-config').html(/*html */ `
            <div style="width: 100%; text-align: center" class="my-3">
                Carregando informações da imagem...
                <div class="spinner-border text-primary mt-2" role="status">
                <span class="visually-hidden"></span>
                </div>
            </div>
        `)

        imagem.on('load', (_) => {
          if (!element(showImageToggle).is(':checked')) return
          form.find('#photo-width').val(imagem.css('width').replace('px', ''))
          form.find('.photo-config').css('display', 'block')
          form.find('.border-config').css('display', 'flex')
          form.find('.config-alert').hide()
          form.find('.loading-config').html('')

          colorPicker.show(form, imagem.css('border-color'))
        })

        return
      }

      imagem.attr({ src: value, 'data-cke-saved-src': value }).css({ borderWidth: '0px' })
      painel.find('.demo-photo').css({ display: 'flex' })
      if (!element(showImageToggle).is(':checked')) return

      painel.addClass('d-flex justify-content-center')
      imagem.css({ display: 'none' })
      form.find('.photo-config').css('display', 'none')
      form.find('.border-config').css('display', 'none')
      form.find('.config-alert').show()
    })
  }

  const show = () => {
    const painel = elementRef().find(IMAGE_PANEL)
    const imagem = elementRef().find(IMAGE_TARGET)

    form.find('.photo-config').css('display', 'block')
    form.find('.border-config').css('display', 'flex')
    form.find('.config-alert').hide()

    element(showImageToggle).prop('checked', imagem.is(':visible'))
    if (painel.find('.demo-photo').is(':visible') || !element(showImageToggle).is(':checked')) {
      form.find('.photo-config').css('display', 'none')
      form.find('.border-config').css('display', 'none')
      form.find('.config-alert').show()
    }

    element(formRef.alt).val(imagem.attr('alt'))
    element(formRef.url).val(imagem.attr('src'))
  }

  const template = /*html*/ `
    <div id="${formRef}">
        <div class="tab-content col-sm-12">
            <div class="input-group">
                <input 
                    type="text"
                    id="${formRef.url}"
                    class="form-control" 
                    value=""
                    placeholder="Url da imagem" 
                    aria-label="Url da imagem"
                />
                <div class="input-group-append">
                    <button 
                    class="btn btn-primary" 
                    type="button" 
                    id="${formRef.gallery}"
                    data-toggle="modal" 
                    data-target="#modal-img-galery"
                    data-type="photo"
                    >
                        <i class="fa fa-upload"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="d-flex col-sm-12 mt-3">
            <label class="col-sm-10 p-0 m-0">Mostrar</label>
            <div class="col-sm-2 p-0 m-0">
                <div class="button-switch col-sm-2">
                    <input 
                        type="checkbox" 
                        id="${showImageToggle}" 
                        class="switch" 
                        checked
                    />
                    <label for="${showImageToggle}" class="lbl-off"></label>
                    <label for="${showImageToggle}" class="lbl-on"></label>
                </div>
            </div>
        </div>
    </div>
    ${
      !onlyGallery
        ? /*html*/ `<div class="tab-content col-sm-12 loading-config"></div>
      <div class="tab-content col-sm-12 photo-config">
          <div class="mt-3">
          <div>
              <label for="${formRef.alt}">Descrição da imagem (opcional)</label>
          </div>
          <input 
              type="text"
              id="${formRef.alt}"
              class="form-control" 
              value=""
              placeholder="Alt Text da imagem" 
              aria-label="Alt da imagem"
          />
          </div>
      </div>`
        : ''
    }
  `

  return {
    componentName: 'imageSelect',
    init,
    show,
    template,
  }
}

export default useImageSelect
