const useHeight = ({
    form,
    keditor,
    component,
    elementRef,
    label,
    customOptions,
    customFormRef,
    fullWidth,
    isFirst,
    isMinHeight = false,
    customClass = '',
  }) => {
    const formRef = customFormRef || 'content-height'
    const element = form && form.find(`#${formRef}`)
    const optionsList = customOptions || [
      { value: 'auto', label: 'Auto' },
      { value: '180px', label: 'Pequeno' },
      { value: '380px', label: 'Médio' },
      { value: '720px', label: 'Grande' },
      { value: '100vh', label: 'Altura da Tela' },
    ]
  
    const init = (callback) => {
      element.on('change', ({ target: { value } } = e) => {
        elementRef().css({ [isMinHeight ? 'minHeight' : 'height']: value })
        //vh and other units are always converted to px, so need to work with data attribute
        elementRef().attr(isMinHeight ? 'data-minheight' : 'data-height', value)
        
        if (typeof callback === "function") callback(value)
      })
    }
  
    const show = () => {
      //vh and other units are always converted to px, so need to work with data attribute
      element.val(
        elementRef()
          .attr(isMinHeight ? 'data-minheight' : 'data-height')
      )
    }
  
    const renderOptions = () => {
      let options = ''
      optionsList.forEach((opt) => {
        options += `<option value="${opt.value}">${opt.label}</option>`
      })
      return options
    }
  
    const optionsTemplate = /*html*/ `
      <div class="tab-content col-sm-${fullWidth ? 12 : 6} ${isFirst ? '' : 'mt-3'} ${customClass}">
        <label for="${formRef}">${label}</label>
        <select id="${formRef}" class="form-control">
          ${renderOptions()}
        </select>
      </div>
      `
  
    const numberTemplate = /*html*/ `
      <div class="col-sm-${fullWidth ? 12 : 6} ${isFirst ? '' : 'mt-3'} ${customClass}">
        <label>${label}</label>
        <div class="row">
          <div class="col">
            <div class="padding-box-inner d-inline">
              <div class="padding-box-inner-item">
                <div class="input-group input-group-sm">
                  <input id="${formRef}" type="number" class="form-control" min="0" />
                  <div class="input-group-append">
                    <span class="input-group-text">px</span>
                  </div>
                </div>
              </div>
              <div class="padding-box-inner-item"></div>
            </div>
          </div>
        </div>
      </div>   
      `
  
    return {
      componentName: 'heightSelector',
      init,
      show,
      optionsTemplate,
      numberTemplate,
    }
  }
  
  export default useHeight
  