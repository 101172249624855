import { verifyHasClass } from '../helpers/helpers'

const useAlignmentBlock = ({ form, elementRef, customFormRef, isFirst }) => {
  const configDefault = customFormRef || 'content-alignment'
  const configMobile = `${configDefault}-mobile`
  const blockConfig = 'block-align'

  const positionList = [
    { value: 'text-left', label: 'Esquerda' },
    { value: 'text-center', label: 'Centro' },
    { value: 'text-right', label: 'Direita' },
  ]

  const element = (name) => form && form.find(`#${name}`)

  const classes = (type) => `${type}-text-center ${type}-text-left ${type}-text-right ${type}-text-justify`

  const init = (customCss = 'textAlign') => {
    element(configDefault).on('change', ({ target: { value } } = e) => {
      elementRef().css(customCss, '')
      elementRef().removeClass(classes('default'))
      elementRef().addClass(value)

      if (elementRef().attr('data-block-mobile') == 'true') {
        elementRef().removeClass(classes('mobile'))
        const mobileValue = `mobile-${value.replace('default-', '')}`
        elementRef().addClass(mobileValue)
        element(configMobile).val(mobileValue)
      }
    })

    element(configMobile).on('change', ({ target: { value } } = e) => {
      elementRef().css(customCss, '')
      elementRef().removeClass(classes('mobile'))
      elementRef().removeClass(classes('default'))
      elementRef().addClass(value)

      elementRef().addClass(element(configDefault).val())
    })

    element(blockConfig).on('click', function (e) {
      const icon = $(this).find('i')

      if (icon.hasClass('fa-lock')) {
        elementRef().attr('data-block-mobile', false)
        element(configMobile).attr('disabled', false)
        elementRef().addClass(element(configMobile).val())
      } else {
        const value = 'mobile-' + element(configDefault).val().replace('default-', '')
        element(configMobile).attr('disabled', true)
        elementRef().attr('data-block-mobile', true)
        elementRef().removeClass(classes('mobile'))
        elementRef().addClass(value)
        element(configMobile).val(value)
      }

      icon.toggleClass('fa-lock')
      icon.toggleClass('fa-unlock')
    })
  }

  const show = () => {
    const hasOldClass = verifyHasClass(elementRef(), [
      'text-center',
      'text-left',
      'text-right',
      'justify-content-center',
      'justify-content-end',
      'justify-content-start',
    ])
    if (hasOldClass) {
      elementRef().removeClass(hasOldClass)
      elementRef().addClass(
        `default-${hasOldClass.replace('justify-content', 'text')} mobile-${hasOldClass.replace(
          'justify-content',
          'text'
        )}`
      )
    }

    const aligment =
      verifyHasClass(elementRef(), [
        'default-text-center',
        'default-text-left',
        'default-text-right',
        'default-text-justify',
      ]) || 'default-text-center'
    const aligmentMobile =
      verifyHasClass(elementRef(), [
        'mobile-text-center',
        'mobile-text-left',
        'mobile-text-right',
        'mobile-text-justify',
      ]) || 'mobile-text-center'
    const isMobileAlignBlocked = elementRef().attr('data-block-mobile') == 'false'

    element(configDefault).val(aligment)
    element(configMobile).val(aligmentMobile)

    const icon = element(blockConfig).find('i')

    switch (!isMobileAlignBlocked) {
      case true:
        icon.removeClass('fa-unlock')
        icon.addClass('fa-lock')
        element(configMobile).attr('disabled', true)
        elementRef().attr('data-block-mobile', true)
        break
      case false:
        icon.removeClass('fa-lock')
        icon.addClass('fa-unlock')
        element(configMobile).attr('disabled', false)
        elementRef().attr('data-block-mobile', false)
        break
    }
  }

  const renderFontOptions = (especialConfig = '') => {
    let fonts = ''
    positionList.forEach((font) => {
      fonts += `<option value="${especialConfig}${font.value}">${font.label}</option>`
    })
    return fonts
  }

  const template = /*html*/ `
    <div class="tab-content col-sm-6 ${isFirst ? '' : 'mt-3'}">
      <label for="${configDefault}">Alinhamento</label>
      <select id="${configDefault}" class="form-control">
        ${renderFontOptions('default-')}
      </select>
    </div>
    <div class="tab-content col-sm-6 ${isFirst ? '' : 'mt-3'}">
    <label for="${configMobile}">Alinhamento celular</label>
      <div class="d-flex align-items-center">
        <span
        id="${blockConfig}"
        class="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock-config-icon" 
        >
        <i class="fa fa-unlock" aria-hidden="true"></i>
        </span>
        <select id="${configMobile}" class="form-control">
          ${renderFontOptions('mobile-')}
        </select>
      </div>
    </div>
    `

  return {
    componentName: 'alignmentSelect',
    init,
    show,
    template,
  }
}

export default useAlignmentBlock
