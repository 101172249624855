import { componentSpacing, applySpacing, verifyHasClass } from '../../helpers/helpers.js'
import initModal from '../../../keditor/modal/initModal.js'
import { hideMobileDesktop } from '../../helpers/form.settings.js'
import { SET_CONTAINER_BACKGROUND } from '../../../constants.js'
import { getRgbish } from './helpers.js'
import ColorPicker from '../../helpers/colorPicker.js'
import CSS_CLASS from '../../../keditor/constants/cssClass.js'
const colorPicker = new ColorPicker()

let CURRENT_CONTAINER_CONFIG = {}
export function initSettingFormContainer(form, keditor) {
  // BORDER
  setBorderRadiusPosition(form, keditor)

  form.find('#bg-border').on('change', function () {
    const section = keditor.getSettingContainer().find('.keditor-container-inner').first()
    const sectionInner = section.children().first()

    let style = 'none'
    if (this.value > 0) {
      style = form.find('#border-style').val()
    }

    sectionInner.css('border-style', style)
    sectionInner.css('border-width', `${this.value}px`)
  })

  form.find('#border-style').on('change', function () {
    const section = keditor.getSettingContainer().find('.keditor-container-inner').first()
    const sectionInner = section.children().first()
    const size = form.find('#bg-border').val()

    sectionInner.css('border-style', this.value)
    sectionInner.css('border-width', `${size}px`)
  })

  colorPicker.init(
    form,
    (color) => {
      console.log(color)
      const section = keditor.getSettingContainer().find('.keditor-container-inner').first()
      const sectionInner = section.children().first()
      sectionInner.css('border-color', color)
    },
    '#bg-border-color'
  )

  form.find('#container-size').on('change', (e) => {
    const { value } = e.target
    const section = keditor.getSettingContainer().find('.keditor-container-inner').first()
    const sectionInner = section.children().first().children()

    if (value === 'normal') return sectionInner.removeClass('cc-w-fluid cc-w-small')

    sectionInner.removeClass('cc-w-fluid cc-w-small')
    sectionInner.addClass(value)
  })

  form.find('#container-height').on('change', (e) => {
    const { value } = e.target
    const section = keditor.getSettingContainer().find('.custom-container').first()
    section.parent().css({ minHeight: value, position: 'relative', height: 'auto' })
    section.attr('data-height', value)
    // section.data('height', value);
  })

  hideMobileDesktop(form, null, keditor, '.keditor-container-inner', 'edit-container')

  //Padding
  let pElement = form.find('.p-element')
  pElement.on('change', function () {
    const section = keditor.getSettingContainer().find('.keditor-container-inner').children().first()
    applySpacing(this, section, 'padding', form)
  })

  //Margin
  let mElement = form.find('.m-element')
  mElement.on('change', function () {
    const section = keditor.getSettingContainer().find('.keditor-container-inner').children()
    const sectionInner = section.first()
    applySpacing(this, sectionInner, 'margin', form)
  })

  let customClass = form.find('.custom-class')
  customClass.on('change', function () {
    const section = keditor.getSettingContainer().find('.keditor-container-inner').first()
    const sectionInner = section.children().first()

    sectionInner.attr('class', this.value)
  })
}

export function showSettingFormContainer(form, container, keditor) {
  const section = container.find('.keditor-container-inner').first()
  const sectionInner = section.children().first()

  const containerHeight = container.find('.custom-container').first() //.parent();
  const containerWidth = verifyHasClass(sectionInner.children(), ['cc-w-small', 'cc-w-fluid'])

  const isSubcontainer = section.parent().hasClass(CSS_CLASS.SUB_CONTAINER)
  form.find('.advanced').show()
  if (isSubcontainer) form.find('.advanced').hide()

  form.find('#container-size').val(containerWidth.length ? containerWidth : 'normal')
  form.find('#container-height').val(containerHeight.data('height') || 'auto')

  const id = sectionInner.attr('id') || container.attr('id')

  const image = sectionInner.css('backgroundImage').split(', url')

  const gradient = getRgbish(image[0]).colors

  const auxObject = {
    elementId: id,
    backgroundColor: {
      type: 'backgroundColor',
      value: sectionInner.css('backgroundColor') || container.css('backgroundColor'),
    },
    backgroundGradient: {
      type: 'backgroundImage',
      initValue: gradient[0],
      finalValue: gradient[1],
      angle: getRgbish(image[0]).angle ? getRgbish(image[0]).angle.replace('deg', '') : 180,
    },
    backgroundImage: {
      type: 'backgroundImage',
      url: image[1] ? `url${image[1]}` : '',
      externalUrl: image[1] ? image[1].replace('("', '').replace('")', '') : '',
    },
    repeat: '',
    position: '',
    size: '',
    index: SET_CONTAINER_BACKGROUND.length,
  }

  CURRENT_CONTAINER_CONFIG = Object.keys(CURRENT_CONTAINER_CONFIG).length ? findContainerBackground(id) : auxObject

  colorPicker.show(form, sectionInner.css('border-color'), '#bg-border-color')

  const bgBorder = form.find('#bg-border')
  bgBorder.val(sectionInner.css('border-width').split('px')[0])

  form
    .find('#border-style')
    .val(sectionInner.css(`border-style`) == 'none' ? 'solid' : sectionInner.css(`border-style`))

  !sectionInner.attr('data-border_lock')
    ? sectionInner.attr('data-border_lock', true)
    : sectionInner.attr('data-border_lock', false)

  const isLocked = sectionInner.attr('data-border_lock') === 'true'
  const icon = form.find('#lock-border-config').find('i')
  const topLeft = form.find('#top-left').val()
  if (sectionInner.css('border-radius').split(' ').length > 1) {
    ;['top-left', 'top-right', 'bottom-left', 'bottom-right'].forEach((radius) => {
      const radiusVal = sectionInner.css(`border-${radius}-radius`).split('px')[0]

      form.find(`#${radius}`).val(radiusVal)
    })
  } else {
    form.find('.radius-position').val(sectionInner.css(`border-radius`).split('px')[0])
  }

  if (isLocked) {
    icon.removeClass('fa-unlock')
    icon.addClass('fa-lock')

    form.find('#top-right,#bottom-right,#bottom-left').attr('disabled', true)
    form.find('#top-right, #bottom-right, #bottom-left').val(topLeft || 0)
  } else {
    icon.removeClass('fa-lock')
    icon.addClass('fa-unlock')

    form.find('#top-right,#bottom-right,#bottom-left').attr('disabled', false)
  }

  // Espaçamentos
  componentSpacing(sectionInner, form, container, keditor, 1)

  hideMobileDesktop(form, sectionInner, keditor, null, '')
}

export function initContainer(container, contentArea) {
  // const section = container.find('.keditor-container-inner').first()
  // const sectionInner = section.children().first()
  // const marginDefault = container.find('.custom-container').parent().data('margin_default')
  // const marginMobile = container.find('.custom-container').parent().data('margin_mobile')
  // if (!marginDefault && !marginMobile) {
  //   const marginTop = container.find('.custom-container').parent().css('marginTop').replace('px', '')
  //   container.find('.custom-container').parent().attr({
  //     'data-margin_default': marginTop,
  //     'data-margin_mobile': marginTop,
  //     'data-spacing-block': true,
  //   })
  // }
  // const image = sectionInner.css('backgroundImage').split(', url')
  // const gradient = getRgbish(image[0]).colors
  // const auxObject = {
  //   elementId: sectionInner.attr('id') || container.attr('id'),
  //   backgroundColor: {
  //     type: 'backgroundColor',
  //     value: sectionInner.css('backgroundColor'),
  //   },
  //   backgroundGradient: {
  //     type: 'backgroundImage',
  //     initValue: gradient[0],
  //     finalValue: gradient[1],
  //     angle: getRgbish(image[0]).angle ? getRgbish(image[0]).angle.replace('deg', '') : 180,
  //   },
  //   backgroundImage: {
  //     type: 'backgroundImage',
  //     url: image[1] ? `url${image[1]}` : '',
  //     externalUrl: image[1] ? image[1].replace('("', '').replace('")', '') : '',
  //   },
  //   repeat: '',
  //   position: '',
  //   size: '',
  //   index: SET_CONTAINER_BACKGROUND.length,
  // }
  // SET_CONTAINER_BACKGROUND.push(auxObject)
  // CURRENT_CONTAINER_CONFIG = auxObject
}

const setBorderRadiusPosition = (form, keditor) => {
  form.find('#lock-border-config').on('click', function () {
    const section = keditor.getSettingContainer().find('.keditor-container-inner').first()
    const sectionInner = section.children().first()

    const icon = $(this).find('i')
    if (icon.hasClass('fa-lock')) {
      sectionInner.attr('data-border_lock', false)
      form.find('#top-right,#bottom-right,#bottom-left').attr('disabled', false)
    } else {
      const topLeft = form.find('#top-left').val()

      sectionInner.attr('data-border_lock', true)
      sectionInner.css('border-radius', `${topLeft}px`)

      form.find('#top-right,#bottom-right,#bottom-left').attr('disabled', true)
      form.find('#top-right,#bottom-right,#bottom-left').val(topLeft)
    }

    icon.toggleClass('fa-lock')
    icon.toggleClass('fa-unlock')
  })

  form.find('.radius-position').on('change', function () {
    const radiusTarget = $(this).attr('id')
    const section = keditor.getSettingContainer().find('.keditor-container-inner').first()
    const sectionInner = section.children().first()
    const isLocked = sectionInner.attr('data-border_lock') === 'true'
    let style = 'border-radius'
    if (!isLocked) {
      style = `border-${radiusTarget}-radius`
    } else {
      form.find('.radius-position').val(this.value)
    }
    sectionInner.css(style, `${this.value}px`)
  })
}

// HELPERS
const findContainerBackground = (id) => {
  const findBackgroundConfig = SET_CONTAINER_BACKGROUND.find((el) => el.elementId === id)

  if (!findBackgroundConfig) {
    SET_CONTAINER_BACKGROUND.push({
      elementId: id,
      backgroundColor: {
        type: 'backgroundColor',
        value: 'rgba(0,0,0,0)',
      },
      backgroundGradient: {
        type: 'backgroundImage',
        initValue: {
          range: 0,
          color: 'rgba(0,0,0,0)',
        },
        finalValue: {
          range: 100,
          color: 'rgba(0,0,0,0)',
        },
        angle: 180,
      },
      backgroundImage: {
        type: 'backgroundImage',
        url: '',
        externalUrl: '',
      },
      repeat: '',
      position: '',
      size: '',
      index: SET_CONTAINER_BACKGROUND.length,
    })

    return findContainerBackground(id)
  }

  return findBackgroundConfig
}

const Iterator = (data, form, action) => {
  const exec = (el, val) => {
    const element = form.find(el)
    element[action](val)
  }

  data.forEach((el) => {
    if (typeof el.selector === 'string') {
      exec(el, el.value)
    }
    el.selector.forEach((select) => {
      exec(select, el.value)
    })
  })
}
