import stickyArrManager from '../helpers/stickyArrManager.js'

const useStickySize = ({form, keditor, elementRef, customFormRef, customOptions, label, fullWidth, isFirst}) => {

    const SICKYSIZEATTR = `data-sticky-size`
    const formRef = customFormRef || `sticky-size-editor`
    const options = customOptions || [
      {
        name: 'Desativado',
        value: 'none',
      },
      {
        name: 'Pequeno',
        value: 'small',
      },
      {
        name: 'Médio',
        value: 'medium',
      },
      {
        name: 'Grande',
        value: 'large',
      },
    ]
  
    const init = () => {
      form.find(`#${formRef}`).on('change', (event) => {
        
        elementRef().attr(SICKYSIZEATTR, event.target.value)

        //Manage the elementRef sticky_function call on footer_system
        stickyArrManager(keditor,elementRef().attr('id'),event.target.value)

      })
    }
  
    const show = () => {
      const curval = elementRef().attr(SICKYSIZEATTR)
      form.find(`#${formRef}`).val(
        options.find((el) => el.value === curval) ? curval : 'none' 
      )
    }
  
    const renderOptions = () => {
      let acc = ''
      options.forEach((element) => {
        acc += `<option value="${element.value || element.name}">${element.name}</option>`
      })
      return acc
    }
  
    const template = /*html*/ `
      <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
        <label for="${formRef}">${label}</label>
        <select id="${formRef}" class="form-control">
          ${renderOptions()}
        </select>
      </div>   
    `
  
    return {
      componentName: 'stickySize',
      init,
      show,
      template,
    }
  }
  
  export default useStickySize