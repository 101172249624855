import { verifyHasClass } from '../helpers/helpers'
import animateArrManager from '../helpers/animations/animateArrManager'
import { hasAnimateWA } from '../helpers/animations/animateArrManager'

const useSelectAnimationType = ({
    form,
    keditor,
    component,
    label,
    customFormRef,
    elementRef,
    customOptions,
    fullWidth,
    isFirst,
  }) => {
    const formRef = customFormRef || `animation-type-editor`
    const element = form && form.find(`#${formRef}`)
    const options = customOptions || [
        {
          name: 'Nenhuma',
          value: '',
          confisref: 'wrap-animations-none'
        },
        {
          name: 'Ao Aparecer',
          value: 'mfanimate',
          confisref: 'wrap-animations-appear-options'
        },
        {
          name: 'Sempre',
          value: 'mfanimatei',
          confisref: 'wrap-animations-options'
        },
      ]
  
    const init = () => {
      form.find(`#${formRef}`).on('change', (event) => {

        //Pega a primeira div real do elemento (keditor wrapa com duas sections no modo edição) pois as classes precisam ser aplicadas/removidas no elemento que vai pra página final
        const finalElement = elementRef().children().first().children().first();


        //Remove all classes, since every change we need to reset to apply the new one
        finalElement.removeClass();

        //Hde configs
        options.forEach((el) => {
            form.find(`#${el.confisref}`).hide()
        })

        //adiciona a classe escolhida
        if(event.target.value == 'mfanimate'){
          animateArrManager(keditor,elementRef().attr('id')) //trabalha com a id da section (id real final que vai ser aplicada na primeira div)
        }else{
          animateArrManager(keditor,elementRef().attr('id'), true)
          finalElement.addClass(event.target.value)
        }
        
        //mostra o restante das configs, referente a classe escolhida, no form
        const option = options.filter((el) => el.value === event.target.value)[0]
        form.find(`#${option.confisref}`).show()

        // Inicializa a config restante (aplica a classe)
        form.find(`#${option.confisref} select`).each((i,el) => {
          finalElement.addClass(el.value)
        })
      })
    }
  
    const show = () => {

      //Pega a primeira div real do elemento (keditor wrapa com duas sections no modo edição) pois as classes precisam ser aplicadas/removidas no elemento que vai pra página final
      const finalElement = elementRef().children().first().children().first();

      //Esconde todas configs
      options.forEach((el) => {
        form.find(`#${el.confisref}`).hide()
      })

      //verifica se tem uma animação de aparição
      if(hasAnimateWA(keditor,elementRef().attr('id'))){
        element.val('mfanimate');
        form.find(`#wrap-animations-appear-options`).show()
      }else{
        const classList = options.map((el) =>{return el.value})
        const curValue = verifyHasClass(finalElement,classList)

        //verifica se existe alguma outra animação
        const option = options.filter((el) => el.value === curValue)[0]
        if (option && option.value) {
          //se existir, atribui o valor ao select e mostra as configs respectivas
          element.val(option.value)
          form.find(`#${option.confisref}`).show()
        }else{
          //se não, atribui zera o select
          element.val('')
        }
      }
      
    }
  
    const renderOptions = () => {
      let acc = ''
      options.forEach((element) => {
        acc += `<option value="${element.value}">${element.name}</option>`
      })
      return acc
    }
  
    const template = /*html*/ `
      <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
        <label for="${formRef}">${label}</label>
        <select id="${formRef}" class="form-control">
          ${renderOptions()}
        </select>
      </div>   
    `
  
    return {
      componentName: 'selectAnimationType',
      init,
      show,
      template,
    }
  }
  
  export default useSelectAnimationType
  