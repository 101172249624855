/**
 * Microcomponent with toggle formart to show or hide a elementRef
 * @param {*} param0 
 * @returns {*}
 */
const useShowElement = ({
  form,
  keditor,
  component,
  elementRef,
  customOptions,
  customFormRef,
  customShowPropValue,
  defaultChecked = false,
  isFirst = false,
  label = 'Mostrar',
}) => {
  const formRef = customFormRef || 'show-rating'
  const element = (target) => form && form.find(`#${target}`)
  const showPropValue = customShowPropValue || 'block'

  const init = (callback) => {
    element(formRef).on('click', function ({ target } = e) {
      const isChecked = $(target).prop('checked')

      if (callback) callback(isChecked)
      
      return elementRef().css({ display: isChecked ? showPropValue : 'none'})
      
    })
  }

  const show = (callback) => {
    element(formRef).prop('checked', elementRef().is(':visible'))

    if (callback) callback(element(formRef).prop('checked'))
  }

  const template = /*html*/ `
    <div class="d-flex col-sm-12 ${!isFirst ? 'mt-3' : ''}">
      <label class="col-sm-10 p-0 m-0">${label}</label>
      <div class="col-sm-2 p-0 m-0">
        <div class="button-switch col-sm-2">
          <input 
              type="checkbox" 
              id="${formRef}" 
              class="switch"
              checked="${defaultChecked}" 
          />
          <label for="${formRef}" class="lbl-off"></label>
          <label for="${formRef}" class="lbl-on"></label>
        </div>
      </div>
    </div>
    `

  return {
    componentName: 'showElement',
    init,
    show,
    template,
  }
}

export default useShowElement
