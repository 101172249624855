const useInputNumber = ({ form, keditor, component, label, customFormRef, elementRef, fullWidth = false}) => {
  const formRef = customFormRef || `input-number`
  const element = form && form.find(`#${formRef}`)

  const init = (callback) => {
    element.on('change', function (event) {
      const { value } = event.target
      let newValue = value
      if (value < 0) return
      if (value < 10) newValue = '0' + value
      if (callback) newValue = callback(this)

      elementRef().html(newValue)
    })
  }

  const show = (customValue) => {
    const value = parseInt(customValue || elementRef().html().trim())

    element.val(value)
  }

  const template = /*html*/ `
  <div class="col-sm-${fullWidth ? 12 : 6} mt-3">
    <label for="${formRef}">${label}</label>
    <input 
      type="number" 
      name="${formRef}" 
      class="form-control" 
      id="${formRef}"
      min="0"
      autocomplete="off"
    />
  </div>   
`

  return {
    componentName: 'inputNumber',
    init,
    show,
    template,
    elementRef
  }
}

export default useInputNumber
