import { verifyHasClass } from '../helpers/helpers'

const useSelectAnimationClasses = ({
    form,
    keditor,
    component,
    label,
    customFormRef,
    elementRef,
    customOptions,
    fullWidth,
    isFirst,
  }) => {
    const formRef = customFormRef || `animation-classes-editor`
    const element = form && form.find(`#${formRef}`)
    const options = customOptions || [
        {
          name: 'Pulso',
          value: 'mfpulse',
        },
        {
          name: 'Elástico',
          value: 'mfbounce',
        },
        {
          name: 'Chacoalho',
          value: 'mfshake',
        },
        {
          name: 'Inclino',
          value: 'mftilt',
        },
      ]
  
    const init = () => {
      form.find(`#${formRef}`).on('change', (event) => {
        //Remove todas as classes
        $(event.target).find(`option`).each((i, el) => {
            elementRef().removeClass(el.value)
        })
        //adiciona a classe escolhida
        elementRef().addClass(event.target.value)
      })
    }
  
    const show = (defaultValue) => {
      let classList = []; 
      form.find(`#${formRef} option`).each((i, el) => {
        classList.push(el.value)
      })
      
      const curValue = verifyHasClass(elementRef(),classList)
      element.val(curValue ? curValue : defaultValue)
    }
  
    const renderOptions = () => {
      let acc = ''
      options.forEach((element) => {
        acc += `<option value="${element.value}">${element.name}</option>`
      })
      return acc
    }
  
    const template = /*html*/ `
      <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
        <label for="${formRef}">${label}</label>
        <select id="${formRef}" class="form-control">
          ${renderOptions()}
        </select>
      </div>   
    `
  
    return {
      componentName: 'selectAnimationClasses',
      init,
      show,
      template,
    }
  }
  
  export default useSelectAnimationClasses
  