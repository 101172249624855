import ColorPicker from '../helpers/colorPicker'
const colorPicker = new ColorPicker()

const useBackgroundGradient = ({ form, elementRef, customFormRef, isFirst = false}) => {
    const formRef = customFormRef || `background-gradient-editor`

    const decodeLinearGradient = (linergradientStr) => {
        let resObj = {
            colorstart: 'rgba(0,0,0,0)',
            colorend: 'rgba(0,0,0,0)',
            offsetstart: '0',
            offsetend: '100',
            direction: 'to bottom'
        }
        if (linergradientStr == 'none') {
            return resObj
        }
        const gradientRegex = /(rgba?\(([0-9]+), ([0-9]+), ([0-9]+),? ?(0|0\.[0-9]+)?\))|([0-9]{1,3}%)/g;
        let gradient = linergradientStr.match(gradientRegex)
        if (gradient.length == 4) {
            resObj.colorstart = gradient[0]
            resObj.offsetstart = gradient[1].replace('%', '')
            resObj.colorend = gradient[2]
            resObj.offsetend = gradient[3].replace('%', '')

            if (linergradientStr.indexOf("to right bottom") != -1) {
                resObj.direction = "to right bottom"
            }else if (linergradientStr.indexOf("to right top") != -1) {
                resObj.direction = "to right top"
            }else if(linergradientStr.indexOf("to right") != -1) {
                resObj.direction = "to right"
            }
        }
        return resObj
    }

    const init = () => {
        //Cor Inicial
        colorPicker.init(
        form,
        (color) => {
            let bgImgCssObj = decodeLinearGradient(elementRef().css('background-image'))
            elementRef().css({"background-image":`linear-gradient(${bgImgCssObj.direction}, ${color} ${bgImgCssObj.offsetstart}%, ${bgImgCssObj.colorend} ${bgImgCssObj.offsetend}%)`})
            
        },
        `#${formRef}-start`
        )
        //Cor Final
        colorPicker.init(
        form,
        (color) => {
            let bgImgCssObj = decodeLinearGradient(elementRef().css('background-image'))
            elementRef().css(`background-image`, `linear-gradient(${bgImgCssObj.direction}, ${bgImgCssObj.colorstart} ${bgImgCssObj.offsetstart}%, ${color} ${bgImgCssObj.offsetend}%)`)
        },
        `#${formRef}-end`
        )
        //Offset Inicial
        form.find(`#${formRef}-range-start`).on('change', function (event) {
            const { value } = event.target
            if (value < 0 || value > 100) return
            
            let bgImgCssObj = decodeLinearGradient(elementRef().css('background-image'))
            elementRef().css(`background-image`, `linear-gradient(${bgImgCssObj.direction}, ${bgImgCssObj.colorstart} ${value}%, ${bgImgCssObj.colorend} ${bgImgCssObj.offsetend}%)`)
        })
        //Offset Final
        form.find(`#${formRef}-range-end`).on('change', function (event) {
            const { value } = event.target
            if (value < 0 || value > 100) return
            
            let bgImgCssObj = decodeLinearGradient(elementRef().css('background-image'))
            elementRef().css(`background-image`, `linear-gradient(${bgImgCssObj.direction}, ${bgImgCssObj.colorstart} ${bgImgCssObj.offsetstart}%, ${bgImgCssObj.colorend} ${value}%)`)
        })
        //Direction
        form.find(`#${formRef}-gradient-direction .nav-item-custom`).on('click', (event) => {
            let bgImgCssObj = decodeLinearGradient(elementRef().css('background-image'))
            elementRef().css(`background-image`, `linear-gradient(${$(event.target).attr('data-value')}, ${bgImgCssObj.colorstart} ${bgImgCssObj.offsetstart}%, ${bgImgCssObj.colorend} ${bgImgCssObj.offsetend}%)`)
        })
    }

    const show = () => {
        // linear-gradient(to right, rgb(255, 255, 255) 30%, rgba(201, 0, 118, 0.48) 45%);
        let bgImgCssObj = decodeLinearGradient(elementRef().css('background-image'))

        //Colors
        colorPicker.show(form, bgImgCssObj.colorstart, `#${formRef}-start`)
        colorPicker.show(form, bgImgCssObj.colorend, `#${formRef}-end`)

        //Range
        form.find(`#${formRef}-range-start`).val(bgImgCssObj.offsetstart)
        form.find(`#${formRef}-range-end`).val(bgImgCssObj.offsetend)

        //Direction
        form.find(`#${formRef}-tobottom`).removeClass('active')
        form.find(`#${formRef}-toright`).removeClass('active')
        form.find(`#${formRef}-tobottomright`).removeClass('active')
        form.find(`#${formRef}-totopright`).removeClass('active')
        form.find(`#${formRef}-${bgImgCssObj.direction.replace(' ','')}`).addClass('active')
    }


    const templateColors = /*html*/ `
        <div class="d-flex flex-wrap ${!isFirst && 'mt-3'}">
            <div class="col-sm-6">
                <label>Cor inicial</label>
                <input data-id="#${formRef}-start" class="color-picker-component form-control" />
            </div>
            <div class="col-sm-6">
                <label>Posição</label>
                <input type="number" id="${formRef}-range-start" class="form-control" value="0" min="0" max="100"/>
            </div>
        </div> 
        <div class="d-flex flex-wrap ${!isFirst && 'mt-3'}">
            <div class="col-sm-6">
                <label>Cor final</label>
                <input data-id="#${formRef}-end" class="color-picker-component form-control" />
            </div>
            <div class="col-sm-6">
                <label>Posição</label>
                <input type="number" id="${formRef}-range-end" class="form-control" value="0" min="0" max="100"/>
            </div>
        </div> 
    `

    const templateDirection = /*html*/ `
        <div class="col-sm-6 mt-3">
            <label>Orientação</label>
            <ul class="nav nav-pills" id="${formRef}-gradient-direction" role="tablist">
                <li class="nav-item col-sm-3 mx-0" style="padding: 1px">
                    <a id="${formRef}-tobottom" class="nav-link nav-item-custom d-flex w-100 justify-content-center" data-toggle="pill" href="#" role="tab" data-value="to bottom" aria-controls="" aria-selected="true">
                        <i class="fa fa-arrow-down" data-value="to bottom" style="transform: rotate(0deg);"></i>
                    </a>
                </li>
                <li class="nav-item col-sm-3 mx-0" style="padding: 1px">
                    <a id="${formRef}-toright" class="nav-link nav-item-custom d-flex w-100 justify-content-center" data-toggle="pill" href="#" role="tab" data-value="to right" aria-controls="" aria-selected="true">
                        <i class="fa fa-arrow-right" data-value="to right" style="transform: rotate(0deg);"></i>
                    </a>
                </li>
                <li class="nav-item col-sm-3 mx-0" style="padding: 1px">
                    <a id="${formRef}-torightbottom" class="nav-link nav-item-custom d-flex w-100 justify-content-center" data-toggle="pill" href="#" role="tab" data-value="to right bottom" aria-controls="" aria-selected="true">
                        <i class="fa fa-arrow-down" data-value="to right bottom" style="transform: rotate(-45deg);"></i>
                    </a>
                </li>
                <li class="nav-item col-sm-3 mx-0" style="padding: 1px">
                    <a id="${formRef}-torighttop" class="nav-link nav-item-custom d-flex w-100 justify-content-center" data-toggle="pill" href="#" role="tab" data-value="to right top" aria-controls="" aria-selected="true">
                        <i class="fa fa-arrow-up" data-value="to right top" style="transform: rotate(45deg);"></i>
                    </a>
                </li>
            </ul>
        </div>
    `

    const template = /*html*/ `
        ${templateColors}
        ${templateDirection}
    `

    return {
        componentName: 'backgroundGradient',
        init,
        show,
        template,
    }
}

export default useBackgroundGradient
