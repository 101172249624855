import { templateForm } from './components/helpers/form.settings.js'
import initTimeDelay from './components/helpers/timeDelay/initSettings.js'
import showTimeDelay from './components/helpers/timeDelay/showSettings.js'
import { initAnimations, showAnimations } from './components/helpers/animations'
import { INTEGRATIONS_TYPE } from './constants.js'

import {
  footerSystemMount,
  headerSystemMount,
  hasIntegrations,
} from './keditor/helpers/helpers.js'

import * as methods from './components/containers/container/methods.js'
import * as templates from './components/containers/container/templates.js'
import { FORM_ADVANCED } from './components/containers/container/advanced.js'
import ContainerComponent from './components/containers/container/methods/index.js'

const containerComponent = new ContainerComponent()
function App() {
  console.log('Initialized APPLICATION')
  $('#content-area').keditor({
    title: 'MakeFunnels',
    clickComponentToShowSetting: false,
    lang: 'PtBr',
    async onSave(content) {
      const self = this

      // guarda o valor atual do FooterSystem para restaura-lo depois da requisição
      const { footer_system } = this.options.contentScripts

      // Prepara a string script da position footer_system para envio
      footer_system.script = footerSystemMount(
        footer_system.content.countdownArr,
        footer_system.content.timeDelayArr,
        footer_system.content.stickyArr,
        footer_system.content.animateArr,
        this.options.contentConversions.conversions[1].enabled
          ? this.options.contentConversions.conversions[1].config
          : ''
      )

      // Prepara a string script da position header_system para envio
      const { header_system } = this.options.contentScripts

      header_system.script = headerSystemMount(
        header_system.content.timeDelayStyle,
        header_system.content.background,
        header_system.content.pixelLeadOnSubmit
      )

      const dataSend = {
        body: content,
        htmls: JSON.stringify(this.options.contentHTMLs.htmls),
        seo: JSON.stringify(this.options.contentSeo),
        css: JSON.stringify(this.options.contentCss),
        scripts: JSON.stringify(Object.values(this.options.contentScripts)),
        tracking: JSON.stringify(this.options.contentTracking.tracking),
        conversions: JSON.stringify(
          this.options.contentConversions.conversions
        ),
        popup: JSON.stringify(this.options.contentPopup),
      }

      hasIntegrations(self, dataSend)

      const config = {
        method: 'post',
        url: this.options.apiSaveContent,
        headers: { Authorization: this.options.accessToken },
        data: dataSend,
      }

      await axios(config)
        .then(function (response) {
          const finalPage = response.data.data.final_url

          self.options.previewURL = finalPage
          const alertSave = $('.save-content')

          const mensagem = `
          <b>Conteúdo salvo com sucesso!</b>
        </br> Você pode acessá-lo
          <a target="_blank" href="http://${finalPage}">clicando aqui</a>
          ou no botão <b>Visitar Página</b>`
          alertSave.fadeIn(1000)
          // self.btnVisit.attr("style", "display: inline !important");
          self.btnVisit.fadeIn(1000)
          alertSave.removeClass('alert-danger alert-warning')
          alertSave.addClass('alert-success')
          alertSave.html(mensagem)
          alertSave.fadeOut(4000)
        })
        .catch(function (error) {
          let err =
            'Não foi possível salvar! Entre em contato com o administrador'
          if (error.response) {
            // Request made and server responded
            ;['tracking', 'seo', 'body', 'css', 'scripts'].map((e) => {
              if (error.response.data.error[e.toString()])
                err = error.response.data.error[e][0]
            })
            console.log(error)
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error)
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error)
          }

          const alertSave = $('.save-content')
          alertSave.fadeIn(500)
          alertSave.removeClass('alert-success')
          alertSave.addClass('alert-danger')
          alertSave.html(err)
          alertSave.fadeOut(3000)
        })
    },
    onInitContainer(container, contentArea) {
      methods.initContainer(container, contentArea)
      containerComponent.container = container
      containerComponent.create()
    },
    containerSettingEnabled: true,
    containerSettingInitFunction(form, keditor) {
      form.empty()
      form[0].innerHTML += templateForm({
        children: templates.FORM_LAYOUT,
        advancedTemplate: FORM_ADVANCED,
        isContainer: true,
      })

      containerComponent.form = form
      containerComponent.keditor = keditor
      containerComponent.init()
      methods.initSettingFormContainer(form, keditor)
      initTimeDelay.call(self, form, keditor)
      initAnimations(form, keditor)
    },

    containerSettingShowFunction(form, container, keditor) {
      containerComponent.container = container
      containerComponent.show()
      methods.showSettingFormContainer(form, container, keditor)
      showTimeDelay.call(self, form, container, keditor)
      showAnimations(form, keditor)
    },
  })
}
$(App())
