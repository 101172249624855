import openGalleryButton from '../../../helpers/gallery.template'

//helpers
const inputRangeColor = ({ id, idRange, maxRange = 100, label = '' }) => /*html*/ `
<div class="d-flex flex-wrap mt-3">
  <div class="col-sm-6">
    <label>Cor ${label}</label>
    <input data-id="#${id}" class="color-picker-component form-control" />
  </div>
  <div class="col-sm-6">
    <label>Posição</label>
    <input 
      type="number" 
      id="${idRange}" 
      class="form-control" 
      value="000" 
      min="0" 
      max="${maxRange}"
    />
  </div>
</div>
`
const inputCards = ({ value = '', icon = '', rotate = 0, size = '4' }) => /*html*/ `
<li class="nav-item col-sm-${size} mx-0" style="padding: 1px">
  <a
    class="nav-link nav-item-custom d-flex w-100 justify-content-center"
    id="${value}"
    data-toggle="pill"
    href="#"
    role="tab"
    data-value="${value}"
    aria-controls=""
    aria-selected="true"
  >
    <i class="fa fa-${icon}" data-value="${value}" style="transform: rotate(${rotate}deg);"></i>
  </a>
</li>
`

const tabBackground = /*html*/ `
<div class="row">
  <div class="col-sm-12">
    <a href="#" style="font-size: 14px" id="clear-bg">Limpar configuração</a>
  </div>
  <div class="col-sm-12 mt-2">
    <label for="input-background">
      Cor do fundo
    </label>
    <input data-id="#input-background" class="color-picker-component form-control" />
  </div>
</div>`

const tabBackgroundGradient = /*html*/ `
<div class="row">
  <div class="col-sm-12">
    <a href="#" style="font-size: 14px" id="clear-bg-gradient">Limpar configuração</a>
  </div>

  ${inputRangeColor({ id: 'gradient-bg-init', idRange: 'range-init', label: 'inicial' })}
  ${inputRangeColor({ id: 'gradient-bg-final', idRange: 'range-final', label: 'final' })}

  <div class="col-sm-12 mt-3">
    <input class="input-checkbox mr-2" type="checkbox" id="invert-color" />
    <label  for="invert-color">Inverter cor</label>
  </div>
  <div class="col-sm-6 mt-3">
    <label>Orientação</label>
    <ul
      class="nav nav-pills"
      id="gradient-direction"
      role="tablist"
    >
    ${inputCards({
      value: 'to bottom',
      icon: 'arrow-down',
      size: 3,
    })}
    ${inputCards({
      value: 'to right',
      icon: 'arrow-right',
      size: 3,
    })}
    ${inputCards({
      value: 'to bottom right',
      icon: 'arrow-down',
      size: 3,
      rotate: -45,
    })}
    ${inputCards({
      value: 'to top right',
      icon: 'arrow-up',
      size: 3,
      rotate: 45,
    })}
    </ul>
  </div>
</div>
`

const tabBackgroundImage = /*html*/ `
<div class="row">
  <div class="col-sm-12">
    <a href="#" style="font-size: 14px" id="clear-bg-image">Limpar configuração</a>
  </div>
  ${openGalleryButton()}
  <div class="col-sm-12 mt-3">
    <label>Tamanho da imagem</label>
    <ul
      class="nav nav-pills"
      id="background-size"
      role="tablist"
    >
      <li class="nav-item col-sm-4 px-0">
        <a
          class="nav-link nav-item-custom active w-100"
          id="contain"
          data-toggle="pill"
          href="#"
          role="tab"
          data-value="contain"
          aria-controls=""
          aria-selected="true"
          >Conter</a
        >
      </li>
      <li class="nav-item col-sm-4 px-0">
        <a
          class="nav-link nav-item-custom w-100"
          id="cover"
          data-toggle="pill"
          href="#"
          role="tab"
          data-value="cover"
          aria-controls=""
          aria-selected="false"
          >Cobrir</a
        >
      </li>
      <li class="nav-item col-sm-4 px-0">
        <a
          class="nav-link nav-item-custom w-100"
          id="auto"
          data-toggle="pill"
          href="#"
          role="tab"
          data-value="auto"
          aria-controls=""
          aria-selected="false"
          >Auto</a
        >
      </li>
    </ul>
  </div>
  <div class="col-sm-6 mt-3">
    <label>Posição da imagem</label>
    <ul
      class="nav nav-pills"
      id="background-position"
      role="tablist"
    >
      ${inputCards({
        value: '0% 0%',
        icon: 'arrow-up',
        rotate: -45,
      })}
      ${inputCards({
        value: ['50% 0%'],
        icon: ['arrow-up'],
      })}
      ${inputCards({
        value: ['100% 0%'],
        icon: ['arrow-up'],
        rotate: 45,
      })}

      ${inputCards({
        value: '0% 50%',
        icon: 'arrow-left',
      })}
      ${inputCards({
        value: '50% 50%',
        icon: 'circle',
      })}
      ${inputCards({
        value: '100% 50%',
        icon: 'arrow-right',
      })}

      ${inputCards({
        value: '0% 100%',
        icon: 'arrow-down',
        rotate: 45,
      })}
      ${inputCards({
        value: '50% 100%',
        icon: 'arrow-down',
      })}
      ${inputCards({
        value: '100% 100%',
        icon: 'arrow-down',
        rotate: -45,
      })}
    </ul>
  </div>
  <div class="col-sm-2"></div>
  <div class="col-sm-4 mt-3">
    <label>Repetição</label>
    <ul
      class="nav nav-pills"
      id="background-repeat"
      role="tablist"
    >
    ${inputCards({
      value: 'repeat-x',
      icon: 'arrows-h',
      size: 6,
    })}
    ${inputCards({
      value: 'repeat-y',
      icon: 'arrows-v',
      size: 6,
    })}
    ${inputCards({
      value: 'repeat',
      icon: 'arrows',
      size: 6,
    })}
    ${inputCards({
      value: 'no-repeat',
      icon: 'times',
      size: 6,
    })}
    </ul>
  </div>

</div>
`

export { tabBackground, tabBackgroundGradient, tabBackgroundImage }
