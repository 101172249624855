const useToggleButton = ({ form, keditor, component, label, elementRef }) => {
  const formRef = 'switch-open-new-page'
  const element = form && form.find(`#${formRef}`)

  const init = () => {
    element.on('click', (event) => {
      const isChecked = $(event.target).prop('checked')

      // isChecked && elementRef().attr('href').indexOf('#') == -1
      //   ? elementRef().attr('target', '_blank')
      //   : elementRef().removeAttr('target')

      if (isChecked) elementRef().attr('target', '_blank')
      else elementRef().removeAttr('target')
    })
  }

  const show = () => {
    element.prop('checked', !!elementRef().attr('target'))
  }

  const template = /*html*/ `
      <div class="d-flex col-sm-12 mt-3">
        <label class="col-sm-10 p-0 m-0">${label}</label>
        <div class="col-sm-2 p-0 m-0">
          <div class="button-switch col-sm-2">
            <input 
              type="checkbox" 
              id="${formRef}" 
              class="switch" 
            />
            <label for="${formRef}" class="lbl-off"></label>
            <label for="${formRef}" class="lbl-on"></label>
          </div>
        </div>
      </div>
    `

  return {
    componentName: 'toggleButton',
    init,
    show,
    template,
  }
}

export default useToggleButton
