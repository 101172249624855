import { allIcons, hmltIconsElementString, filter } from '../helpers/completeListIcons'
import { verifyHasClass, changeOneClass } from '../helpers/helpers'

const useIconSelect = ({
  form,
  keditor,
  component,
  label,
  customFormRef,
  customToggleFormRef,
  elementRef,
  defaultChecked,
  hasShowConfig = true,
  isFirst = false,
  fullWidth = true,
  noPadding = false,
}) => {
  const formRef = customFormRef || `icon-select-editor`
  const showIconToggle = customToggleFormRef || 'showIconToggle'
  const showIconToggleElement = form && form.find(`#${showIconToggle}`)
  const dropdownButton = 'dropdownIcons'
  const iconsList = 'iconsList'

  const init = (callback) => {
    const element = form && form.find(`#${formRef}`)
    const search = element.find('.searchIcon')
    const menu = element.find('.menuItems')
    const icons = element.find(`#${iconsList}`)
    const button = element.find(`#${dropdownButton}`)

    //add event search
    search.on('keyup', (event) => {
      filter(event.target.value, icons, menu)
    })

    element.on('click', '.dropdown-item', function () {
      const icon = elementRef().find('.icon')
      let type = $(this).find('i').attr('class').split('fa ')
      const label = type[1].split('-').join(' ')
      console.log(label)

      if (!callback) {
        const classString = changeOneClass(icon, 'fa-', type[1])
        icon.removeClass()
        icon.addClass(classString)
      } else {
        callback(type[1])
      }

      button.html(`<i class="fa ${type[1]} mx-1"></i>${label}`)
    })

    //showIconToggle
    showIconToggleElement.on('click', (event) => {
      const icon = elementRef().find('.icon')
      const isChecked = $(event.target).prop('checked')

      if (isChecked) {
        icon.removeAttr('hidden')
        icon.css('display', 'inline-block')
      } else {
        icon.attr('hidden', 'true')
        icon.css('display', 'none')
      }
    })
  }

  // const show = () => {
  function show(callback) {
    const icon = elementRef().find('.icon')
    const element = form && form.find(`#${formRef}`)

    const button = element.find(`#${dropdownButton}`)
    const dropdownIcon = !callback ? verifyHasClass(icon, Object.keys(allIcons)) : callback()
    const label = dropdownIcon.split('-').join(' ')

    button.html(`<i class="fa ${dropdownIcon} mx-1"></i> ${label}`)

    //showIconToggle
    showIconToggleElement.prop('checked', !icon.attr('hidden'))
  }

  const template = /*html*/ `
        <div class="col-sm-${fullWidth ? 12 : 6} ${isFirst && 'mt-3'} ${noPadding && 'p-0'}" id="${formRef}">
            <label>${label}</label>
            <div class="dropdown">
                <button 
                    id="${dropdownButton}" 
                    class="col-sm-12 btn btn-dropdown dropdown-toggle d-flex align-items-center justify-content-between" 
                    type="button" 
                    data-toggle="dropdown" 
                    aria-haspopup="true" 
                    aria-expanded="false"
                ></button>
                <div class="dropdown-menu" aria-labelledby="${dropdownButton}">
                    <div class="dropdown-header">
                        <input
                        placeholder="Digite aqui o nome do icone" 
                        type="search" 
                        class="form-control searchIcon" 
                        autofocus="autofocus"
                    >
                    </div>
                    <div id="${iconsList}" class="menuItems">${hmltIconsElementString()}</div>
                </div>
            </div>
            ${
              hasShowConfig
                ? /*html */ `
                <div class="d-flex col-sm-12 mt-3">
                    <label class="col-sm-10 p-0 m-0">Mostrar</label>
                    <div class="col-sm-2 p-0 m-0">
                        <div class="button-switch col-sm-2">
                            <input 
                                type="checkbox" 
                                id="${showIconToggle}" 
                                class="switch" 
                                checked="${defaultChecked || false}"
                            />
                            <label for="${showIconToggle}" class="lbl-off"></label>
                            <label for="${showIconToggle}" class="lbl-on"></label>
                        </div>
                    </div>
                </div>
              `
                : ''
            }
        </div>   
  `

  return {
    componentName: 'iconSelect',
    init,
    show,
    template,
  }
}

export default useIconSelect
