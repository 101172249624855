import { default as useBoxShadow } from '../../microComponents/BoxShadow.js';

const BoxShadow = useBoxShadow({
  label: 'Sombra',
  customOptions: [
    {
      name: 'Sem sombra',
      value: 0,
      hex: 'none',
    },
    {
      name: 'Sombra leve',
      value: 1,
      hex: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    },
    {
      name: 'Sombra alta',
      value: 2,
      hex: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
  ],
});

export const FORM_ADVANCED = /*html*/ `
<div class="d-flex flex-wrap">
  <div class="advanced tab-content col-lg-12 col-sm-12 mt-3">
    <label for="container-height">Altura mínima do container</label>
    <select id="container-height" class="form-control">
      <option value="auto">Automático</option>
      <option value="50vh">50%</option>
      <option value="75vh">75%</option>
      <option value="100vh">100%</option>
    </select>
    <p class="text-muted" style="margin-top: 5px; font-size: 12px !important; font-style: italic;">
    *Altura mínima em relação a tela
    </p>
  </div>
</div>
`;
