import { verifyHasClass } from '../helpers/helpers'

const useAlignmentWithIcon = ({ form, keditor, component, elementRef, label, isFirst }) => {
  // const formRef = customFormRef || 'content-alignment'
  const element = (formRef) => form && form.find(`#${formRef}`)
  const alignment = (type) => elementRef().attr(`data-align-${type}`)
  const classes = (type) => `${type}-text-center ${type}-text-left ${type}-text-right ${type}-text-justify`
  const style = (type, value) => `${type}-text-${value}`

  const defaultConfig = 'text-alignment'
  const mobileConfig = 'text-alignment-mobile'
  const blockConfig = 'block-alignment-mobile'

  const init = (callback) => {
    // DEFAULT CONFIG
    element(defaultConfig).on('click', function (e) {
      const value = $(e.target).attr('data-value')
      elementRef().removeClass(classes('default'))
      elementRef().addClass(style('default', value))

      if (callback) callback({ target: 'default', value: style('default', value) })

      if (elementRef().attr('data-block-align') && JSON.parse(elementRef().attr('data-block-align'))) {
        elementRef().removeClass(classes('mobile'))
        elementRef().addClass(style('mobile', value))

        element(mobileConfig).find('.nav-item-custom').removeClass('active')
        element(mobileConfig).find(`[data-value="${value}"]`).addClass('active')

        if (callback) callback({ target: 'mobile', value: style('mobile', value) })
      }
    })

    // MOBILE CONFIG
    element(mobileConfig).on('click', function (e) {
      const value = $(e.target).attr('data-value')
      elementRef().removeClass(classes('mobile'))
      elementRef().addClass(style('mobile', value))

      if (callback) callback({ target: 'mobile', value: style('mobile', value) })
    })

    //BLOCK CONFIG
    element(blockConfig).on('click', function (e) {
      const icon = $(this).find('i')

      if (icon.hasClass('fa-lock')) {
        elementRef().attr('data-block-align', false)
        element(mobileConfig).find('.nav-item-custom').removeClass('disabled')

        if (callback) callback({ target: 'block', isBlock: false })
      } else {
        elementRef().attr('data-block-align', true)
        element(mobileConfig).find('.nav-item-custom').addClass('disabled')

        elementRef().removeClass(classes('mobile'))

        const defaultClass = verifyHasClass(elementRef(), classes('default').split(' '))

        elementRef().addClass(defaultClass.replace('default', 'mobile'))

        element(mobileConfig).find('.nav-item-custom').removeClass('active')
        element(mobileConfig)
          .find(`[data-value="${defaultClass.replace('default-text-', '')}"]`)
          .addClass('active')

        if (callback) {
          callback({ target: 'block', isBlock: true })
          callback({ target: 'mobile', value: defaultClass.replace('default', 'mobile') })
        }
      }
      icon.toggleClass('fa-lock')
      icon.toggleClass('fa-unlock')
    })
  }

  const show = (callback) => {
    if (!elementRef().hasClass(classes('default') + ' ' + classes('mobile'))) {
      elementRef().addClass(style('default', 'left'))
      elementRef().addClass(style('mobile', 'left'))
      elementRef().attr('data-block-align', true)
    }

    const defaultClass = verifyHasClass(elementRef(), classes('default').split(' '))
    const mobileClass = verifyHasClass(elementRef(), classes('mobile').split(' '))

    element(defaultConfig).find('.nav-item-custom').removeClass('active')
    element(defaultConfig)
      .find(`[data-value="${defaultClass.replace('default-text-', '')}"]`)
      .addClass('active')

    element(mobileConfig).find('.nav-item-custom').removeClass('active')
    element(mobileConfig)
      .find(`[data-value="${mobileClass.replace('mobile-text-', '')}"]`)
      .addClass('active')

    element(blockConfig).find('i').removeClass('fa-lock fa-unlock')
    element(blockConfig)
      .find('i')
      .addClass(
        elementRef().attr('data-block-align') && JSON.parse(elementRef().attr('data-block-align'))
          ? 'fa-lock'
          : 'fa-unlock'
      )

    if (element(blockConfig).find('i').hasClass('fa-lock')) {
      element(mobileConfig).find('.nav-item-custom').addClass('disabled')
    } else {
      element(mobileConfig).find('.nav-item-custom').removeClass('disabled')
    }
  }

  // TEMPLATES
  const inputCards = ({ value = '', size = '3' }) => /*html*/ `
    <li class="nav-item col-sm-${size} mx-0.5" style="padding: 1px">
      <a
        class="nav-link nav-item-custom d-flex justify-content-center"

        id="${value}"
        data-toggle="pill"
        href="#"
        role="tab"
        data-value="${value}"
        aria-controls=""
        aria-selected="true"
      >
        <i class="fa fa-align-${value}" data-value="${value}"></i>
      </a>
    </li>
  `

  const Alinhamento = (type = '') => /*html*/ `
  <ul class="nav nav-pills" id="text-alignment${type}" role="tablist">
    ${inputCards({
      value: 'left',
    })}
    ${inputCards({
      value: 'center',
    })}
    ${inputCards({
      value: 'right',
    })}
    ${inputCards({
      value: 'justify',
    })}
  </ul>`

  const template = /*html*/ `
  <div class="d-flex flex-wrap">
    <div class="col-sm-6 ${!isFirst ? 'mt-3' : ''}">
      <label>Computador</label>
      ${Alinhamento()}
    </div>

    <div class="col-sm-6 ${!isFirst ? 'mt-3' : ''}">
      <div class="d-flex align-items-center" style="margin-bottom: .5rem;">
        <span
          id="${blockConfig}"
          class="d-flex p-1 mr-1 rounded text-center justify-content-center align-items-center lock-config-icon" 
        >
          <i class="fa fa-unlock" aria-hidden="true"></i>
        </span> 
        <label class="mb-0">Celular</label> 
      </div>
      ${Alinhamento('-mobile')}
    </div>
  </div>
    `

  return {
    componentName: 'alignmentWithIcon',
    init,
    show,
    template,
  }
}

export default useAlignmentWithIcon
