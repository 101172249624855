const formRef = 'margin-top-editor';
const element = (form) => form && form.find(`#${formRef}`);
const display = (form) => form && form.find('#display');

export default {
  init: (form, component) => {
    form.on('input', `#${formRef}`, (event) => {
      const value = event.target.value;
      
      display(form).val(value);
      component().children('section').children().css('margin-top', `${value}px`);
    });
  },

  show: (form, component) => {
    const marginTop = parseInt(component.children('section').children().css('margin-top'));
    
    element(form).val(marginTop);
    display(form).val(marginTop);
  },

  template: /*html*/ `
    <div class="tab-content mt-3">
      <label>Espaçamento Externo Superior</label>

      <div class="row">
        <div class="col-sm-9" style="display: flex; margin-right: 0px !important;">
          <input  id="${formRef}" type="range" style="width: 100%" max="100" min="-100" />
        </div>

        <div class="col-sm-3">
          <div class="input-group input-group-sm">
            <input type="text" id="display" class="form-control" value="0" readonly />
          </div>
        </div>
      </div>
    </div>
  `,
};
