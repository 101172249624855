const useSelect = ({
    form, 
    label,
    customFormRef,
    elementRef,
    customOptions,
    fullWidth, 
    isFirst
  }) => {
    const formRef = customFormRef || 'default-select-form'
    const element = form && form.find(`#${formRef}`)
  
    const options = customOptions || [
      {
        value: 'default-option',
        label: 'Padrão',
      },
      {
        value: 'second-option',
        label: 'Segunda Opção',
      },
    ]
  
    // Renderiza as opções
    const renderOptions = () => {
        let acc = ''
        options.forEach((option) => {
          acc += `<option value="${option.value || option.label}">${option.label}</option>`
        })
        return acc
    }
  
    const init = ({callback}) => {
        element.on('change', function (event) {
            const { value } = event.target
            if (typeof callback === "function") callback(value, elementRef)
        })
    }
  
    const show = ({customValue, callback}) => {
      const value = customValue || (typeof callback === "function" && callback(elementRef)) || 'default-option'

      element.val(
        options.find((el) => el.value === value) ? value : 'default-option' 
      )
    }
  
    const template = /*html*/ `
        <div class="col-sm-${fullWidth ? '12' : '6'} ${!isFirst && 'mt-3'}">
            <label for="${formRef}">${label}</label>
            <select id="${formRef}" class="form-control">
                ${renderOptions()}
            </select>
        </div>  
    `
  
    return {
      componentName: 'select',
      options,
      init,
      show,
      template,
    }
  }
  
  export default useSelect
  