const useInputURL = ({ form, keditor, component, label, customFormRef, elementRef}) => {
    const labelUrlValue = label || 'Redirecionar para'
    const formRef = customFormRef || `input-url`
    const element = form && form.find(`#${formRef}`)

    //TODO
    // Pegar o MicroComponent de InputURL e atualizar nos elementos: Quiz, Icon, Image, Button, 

    /**
     * Adds https:// to the beginning of url if not have any scheme in it
     * @param {String} url 
     * @returns {String}
     */
    function insertScheme(url) {
        const regex = /^(http:\/\/|https:\/\/)/
        let valueMatch = url.match(regex)
        if (valueMatch && valueMatch[0]) {
            return url
        }else{
            return 'https://' + url
        }
    }
  
    const init = ({changeHref, callback}) => {
        element.on('change', function (event) {
            const { value } = event.target

            let newValue = insertScheme(value)
            if (value != newValue) {
                element.val(newValue)
            }

            if (typeof callback === "function") callback(newValue, elementRef)

            if (changeHref) elementRef().attr("href", newValue)

        })
    }
  
    const show = ({customValue, callback}) => {
      const value = customValue || (typeof callback === "function" && callback(elementRef)) || elementRef().attr("href").trim()
  
      element.val(value)
    }
  
    const template = /*html*/ `
    <div class="mt-3">
      <label for="${formRef}">${labelUrlValue}</label>
      <input 
        type="text" 
        name="${formRef}" 
        class="form-control" 
        id="${formRef}"
        placeholder="https://"
      />
    </div>   
  `
  
    return {
      componentName: 'inputURL',
      init,
      show,
      template,
      elementRef
    }
  }
  
  export default useInputURL  