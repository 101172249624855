const useFontSelector = ({ form, keditor, component, label, elementRef, fullWidth, isFirst, customFormRef, customComponentName }) => {
  const formRef = customFormRef || 'font-selector'
  const element = form && form.find(`#${formRef}`)
  const fontFamilyList = [
    'Roboto',
    'Fira Sans',
    'Poppins',
    'Fjalla One',
    'Lato',
    'Noto Sans',
    'Nunito Sans',
    'Montserrat',
    'Open Sans',
    'Oswald',
    'Oxygen',
    'Reenie Beanie',
    'Rock Salt',
    'Shadows Into Light Two',
    'Source Sans Pro',
    'Ubuntu',
    'Inter',
    'Raleway',
    'Sora'
  ]

  const init = () => {
    form.find(`#${formRef}`).on('change', (event) => {
      elementRef().attr('data-font-family', event.target.value)
      elementRef().css('font-family', event.target.value)
    })
  }

  const show = () => {
    form.find(`#${formRef}`).val(elementRef().attr('data-font-family'))
  }

  const renderFontOptions = () => {
    let fonts = ''
    fontFamilyList.forEach((font) => {
      fonts += `<option value="${font}" style="font-family:'${font}'">${font}</option>`
    })
    return fonts
  }

  const template = /*html*/ `
    <div class="tab-content col-sm-${fullWidth ? 12 : 6} ${isFirst ? '' : 'mt-3'}">
      <label for="${formRef}">${label}</label>
      <select id="${formRef}" class="form-control">
        ${renderFontOptions()}
      </select>
    </div>
    `

  // const template = /*html*/`
  // <div class="tab-content col-sm-12 mt-3">
  //   <div class="dropdown">
  //     <button
  //       id="dLabel"
  //       class="dropdown-select"
  //       type="button"
  //       data-toggle="dropdown"
  //       aria-haspopup="true"
  //       aria-expanded="false">
  //         ${label}
  //     </button>
  //     <ul class="dropdown-menu" aria-labelledby="dLabel">
  //       ${renderFontOptions()}
  //     </ul>
  //   </div>
  // </div>
  // `

  return {
    componentName: customComponentName || 'fontSelector',
    init,
    show,
    template,
  }
}

export default useFontSelector

// this.TextComponent = Text(
//   { form: this.form },
//   {
//       selector: this.#elements.inputText.data,
//       elementRef: () => this.getRef(this.#buttonRef).find('.btn-title')
//   }
// )
